import React, { FC } from 'react';
import { Avatar, Button, Flex } from 'antd';
import { useMatchDetailsService } from '../MatchDetailsService';
import { Container, DisplayText } from '../../../components';
import { styled } from 'styled-components';
import { colors } from '../../../styles';
import { MatchDetails } from 'src/models';

interface Props {
  matchDetails: MatchDetails;
}

const WhoWillWin: FC<Props> = ({ matchDetails }) => {
  const { vote, myVote } = useMatchDetailsService();
  const [isPressed, setIsPressed] = React.useState(!!myVote);
  const { home, draw, away } = matchDetails.matchVote;
  const totalVotes = home + draw + away;

  const onVote = (type: string) => {
    setIsPressed(!isPressed);
    vote(type);
  };

  return (
    <Container bordered innerSpace={15}>
      <Flex vertical align="center" gap={10}>
        <DisplayText value={`${matchDetails.match.home.name} - ${matchDetails.match.away.name}`} textAlign="center" />
        <DisplayText size="sectionTitle" weight="semiBold" value="WHO WILL WIN?" />
        <Flex gap={5}>
          {!isPressed && (
            <>
              <StyledButton onClick={() => onVote('Home')} $bgColor={colors.primaryLight}>
                <Avatar size={35} src={matchDetails.match.home.logoUrl} />
              </StyledButton>
              <StyledButton onClick={() => onVote('Draw')} $bgColor={colors.grey}>
                <DisplayText size="sectionTitle" weight="semiBold" value="X" textAlign="center" />
              </StyledButton>
              <StyledButton onClick={() => onVote('Away')} $bgColor={colors.blue}>
                <Avatar size={35} src={matchDetails.match.away.logoUrl} />
              </StyledButton>
            </>
          )}
          {isPressed && (
            <>
              <StyledButton $bgColor={colors.primaryLight}>
                <DisplayText weight="semiBold" size="sectionTitle" value={home} textAlign="center" />
              </StyledButton>
              <StyledButton $bgColor={colors.grey}>
                <DisplayText weight="semiBold" size="sectionTitle" value={draw} textAlign="center" />
              </StyledButton>
              <StyledButton $bgColor={colors.blue}>
                <DisplayText weight="semiBold" size="sectionTitle" value={away} textAlign="center" />
              </StyledButton>
            </>
          )}
        </Flex>

        <DisplayText value={`${totalVotes} people voted`} />
      </Flex>
    </Container>
  );
};

const StyledButton = styled(Button)<{
  $bgColor?: string;
}>`
  width: 80px;
  height: 50px;
  background-color: ${props => props.$bgColor};
`;

export default WhoWillWin;
