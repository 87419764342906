import React, { FC } from 'react';
import { Flex } from 'antd';
import { DisplayHeader } from '../../../components';
import { WinRate } from './WinRate';
import { ProfitChart } from './ProfitChart';
import { BetsStatistics } from './BetsStatistics';
import { Performance } from './Performance';
import { ProfitStatistics } from './ProfitStatistics';
import { useProfileService } from '../ProfileService';

export const ProfileSummary: FC = () => {
  const { userStats } = useProfileService();
  if (!userStats) return <></>;

  return (
    <Flex vertical gap={20}>
      <Flex justify="space-between">
        <WinRate userStats={userStats} />
        <ProfitChart userStats={userStats} />
      </Flex>

      <DisplayHeader value="statistics for the current month" />

      <Flex justify="space-between">
        <ProfitStatistics userStats={userStats} />
        <BetsStatistics userStats={userStats} />
      </Flex>

      <Flex vertical gap={20}>
        <DisplayHeader value="perfomance by month" />
        <Performance userStats={userStats} />
      </Flex>
    </Flex>
  );
};
