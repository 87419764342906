import React, { FC } from 'react';
import { DisplayText } from '.';
import { colors } from 'src/styles';

interface Props {
  value: string;
}

export const DisplayHeader: FC<Props> = ({ value }) => (
  <DisplayText value={value} size="sectionTitle" weight="semiBold" color={colors.black} uppercase header />
);