import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import CookieConsent from 'react-cookie-consent';
import LayoutContainer from './layout/LayoutContainer';
import { ConfirmCodeModal, CreateBetModal, LoginModal, SignUpModal } from './components';
import { ApiProvider } from './api';

const App: FC = () => (
  <>
    <ApiProvider>
      <LayoutContainer>
        <Outlet />
        <Toaster />
        <CreateBetModal />
        <LoginModal />
        <SignUpModal />
        <ConfirmCodeModal />
      </LayoutContainer>
    </ApiProvider>
    <CookieConsent buttonText="Accept" containerClasses="cookie-consent-container">
      I accept using cookies
    </CookieConsent>
  </>
);

export default App;
