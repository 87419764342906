import { create } from 'zustand';
import { useCognito } from '../../auth';
import { useAuthApi } from '../../api';
import { useMutation } from '@tanstack/react-query';
import { VerifyUserRequest } from '../../models';
import toast from 'react-hot-toast';

interface State {
  display: boolean;
  setDisplay(display: boolean): void;
}

const useStore = create<State>()(set => ({
  display: false,
  setDisplay: display => set(state => ({ ...state, display })),
}));

export function useConfirmCodeModalService() {
  const store = useStore();
  const { resendCode: authResendCode, verify: authVerify } = useAuthApi();
  const { username, setCurrentUser } = useCognito();

  const verifyResult = useMutation({
    mutationFn: (req: VerifyUserRequest) => authVerify(req),
    onSuccess: () => {
      setCurrentUser();
      toast.success('Confirmation code has been verified');
      hide();
    },
  });

  const resendCodeResult = useMutation({
    mutationFn: (username: string) => authResendCode(username),
    onSuccess: () => {
      toast.success('Confirmation code has been sent to email address');
    },
  });

  const verify = ({ code }: any) => {
    verifyResult.mutate({ username, code });
  };

  const resendCode = () => {
    resendCodeResult.mutate(username);
  };

  const show = () => {
    store.setDisplay(true);
  };

  const hide = () => {
    store.setDisplay(false);
  };

  return {
    display: store.display,
    hide,
    show,
    resendCode,
    verify,
  };
}
