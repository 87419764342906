import React, { FC } from 'react';
import { Avatar, Button, Flex } from 'antd';
import { DisplayText, useCreateBetModalService, Container } from '../../../components';
import { styled } from 'styled-components';
import { colors } from '../../../styles';
import { Prediction } from '../../../models';

interface Props {
  matchId: string;
  prediction?: Prediction;
  src: string;
  title: string;
}

export const BetAvatarButton: FC<Props> = ({ matchId, prediction, src, title }) => {
  const { show } = useCreateBetModalService();
  const valueText = prediction?.value ? `${prediction.value}%` : '';
  const oddText = prediction?.odd ?? '';
  const footerText = valueText && oddText ? `${valueText} / ${oddText}` : `${valueText}${oddText}`;

  return (
    <BigButton disabled={!prediction} onClick={() => show(prediction!.token, matchId)}>
      <Flex vertical gap={10}>
        <Container height={70}>
          <Flex vertical align="center" justify="center" gap={5}>
            <Avatar size={33} src={src} />
            <DisplayText value={title} size="extraSmall" textAlign="center" />
          </Flex>
        </Container>

        {footerText && (
          <ValueContainer $value={prediction?.value} align="center" justify="center">
            <DisplayText value={footerText} size="extraSmall" />
          </ValueContainer>
        )}
      </Flex>
    </BigButton>
  );
};

const BigButton = styled(Button)`
  height: 150px;
  width: 100px;
  border: 1px solid ${colors.greyDark};
`;

const ValueContainer = styled(Flex)<{ $value?: number }>`
  height: 25px;
  background-color: ${props => {
    if (!props.$value) return colors.greyLight;
    if (props.$value < 75) return colors.redLight;
    if (props.$value < 85) return colors.grey;
    return colors.primaryLight;
  }};
  border: ${props => {
    if (props.$value === null) {
      return `1px solid ${colors.grey}`;
    } else {
      return ``;
    }
  }};
  border-radius: 5px;
  padding: 2px 3px;
  margin-top: 7px;
`;
