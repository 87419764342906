import React, { FC } from 'react';
import MatchesGrid from './components/MatchesGrid';
import MatchesGridFilters from './components/MatchesGridFilters';
import { Recommendations, WelcomeWindow } from './components';
import { useMatchesListService } from './MatchesListService';
import { Container, Loading, Space } from '../../components';

export const MatchesListScreen: FC = () => {
  const { loading } = useMatchesListService();
  const ref = React.useRef<HTMLElement>(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Loading loading={loading}>
      <WelcomeWindow handleOnClick={handleClick} />

      <Container width={1400}>
        <Space size={40} />
        <Recommendations />
        <Space size={40} />

        <Container width={1000}>
          <MatchesGridFilters ref={ref} />
          <Space size={40} />
          <MatchesGrid />
          <Space size={40} />
        </Container>
      </Container>
    </Loading>
  );
};
