import React, { FC } from 'react';
import { colors } from '../../../../styles';
import { DisplayDecimal, DisplayPercentage, DisplayText, Table } from '../../../../components';
import { ColumnsType } from 'antd/es/table';
import { Avatar, Flex } from 'antd';
import { DisplayInjury } from './index';
import { AttackingPlayer, Injury } from '../../../../models';

interface Props {
  players: AttackingPlayer[];
}

const columns: ColumnsType<AttackingPlayer> = [
  {
    key: 'team',
    title: 'Team',
    dataIndex: 'team',
    align: 'center',
    width: 50,
    render: (team: string, _, index: number) => {
      if (index === 0 || index === 3) {
        let rowSpan = 3;
        const backgroundColor = index === 0 ? `${colors.primaryLight}` : `${colors.blue}`;
        return {
          props: {
            rowSpan,
            style: { backgroundColor },
          },
          children: <Avatar size={30} src={team} />,
        };
      }
      return { props: { rowSpan: 0 } };
    },
  },
  {
    key: 'player',
    title: 'Player',
    align: 'center',
    render: (row: AttackingPlayer) => (
      <Flex gap={5} align="center">
        <Avatar size={30} src={row.avatar} />
        <DisplayText value={row.name} />
      </Flex>
    ),
  },
  {
    key: 'rating',
    title: 'Rating',
    dataIndex: 'rating',
    align: 'center',
    width: 80,
    render: (rating: string) => <DisplayText value={rating} textAlign="center" />,
  },
  {
    key: 'minutes',
    title: 'Minutes',
    dataIndex: 'minutes',
    align: 'center',
    width: 80,
    render: (minutes: number) => <DisplayPercentage value={minutes} textAlign="center" />,
  },
  {
    key: 'averagePoints',
    title: 'Points',
    align: 'center',
    width: 100,
    render: (row: AttackingPlayer) => {
      const value = `${row.averagePointsWithPlayer}/${row.averageTeamPoints}/${row.averagePointsWithoutPlayer}`;
      return <DisplayText value={value} textAlign="center" />;
    },
  },
  {
    key: 'goals',
    title: 'Goals(P)',
    dataIndex: 'goals',
    align: 'center',
    width: 90,
    render: (value: number) => <DisplayText value={value} textAlign="center" />,
  },
  {
    key: 'shots',
    title: 'Shots Per Game',
    dataIndex: 'averageShotsPerGame',
    align: 'center',
    width: 90,
    render: (value: number) => <DisplayDecimal value={value} textAlign="center" />,
  },
  {
    key: 'goalPerShot',
    title: 'Goal Per Shot',
    dataIndex: 'goalPerShot',
    align: 'center',
    width: 90,
    render: (value: number) => <DisplayDecimal value={value} textAlign="center" />,
  },
  {
    key: 'injury',
    dataIndex: 'injury',
    title: 'Availability',
    width: 90,
    render: (injury?: Injury) => <DisplayInjury injury={injury} />,
  },
];

export const AttackingPlayersTable: FC<Props> = ({ players }) => {
  return (
    <Flex vertical gap={10}>
      <Table showHeader pagination={false} columns={columns} data={players} rowKey={'team'} cellPadding={8} />
    </Flex>
  );
};
