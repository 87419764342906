import React, { FC } from 'react';
import { Avatar, Col, Flex, Row } from 'antd';
import { MatchDetails, MatchStatus } from '../../../models';
import { Container, DisplayDate, DisplayText, FlashingCircle, ResultBadge, Space } from '../../../components';
import { styled } from 'styled-components';
import { colors } from '../../../styles';
import image from '../../../assets/img/matchPreviewBackground.png';

interface MatchHeaderProps {
  matchDetails: MatchDetails;
}

const matchStatusMap: { [key: string]: string } = {
  [MatchStatus.ToBeDefined]: "To be defnied",
  [MatchStatus.NotStarted]: "Not Started",
  [MatchStatus.Finished]: "Finished",
  [MatchStatus.Postponed]: "Postponed",
  [MatchStatus.Cancelled]: "Cancelled"
}

const MatchHeader: FC<MatchHeaderProps> = ({ matchDetails }) => {
  const { match, homeTeamMatches, awayTeamMatches } = matchDetails;
  const homeMatches = homeTeamMatches.slice(0, 5).reverse();
  const homeTeam = match.home;
  const awayMatches = awayTeamMatches.slice(0, 5);
  const awayTeam = match.away;

  return (
    <ImageBackground>
      <Container width={1400}>
        <Flex vertical align="center">
          <Avatar size={50} src={match.countryFlag} />
          <DisplayText value={match.league.toUpperCase()} size="title" weight="semiBold" color="white" />
        </Flex>

        <TimeContainer justify="space-around">
          <Flex align="center" gap={20}>
            <DisplayDate date={match.scheduledAt} customFormat='DD.MM.YYYY' size="sectionTitle" weight="semiBold" color="white" />
            <DisplayDate date={match.scheduledAt} customFormat='HH:mm' size="sectionTitle" weight="semiBold" color="white" />
          </Flex>
        </TimeContainer>

        <Space size={20} />
        <Row>
          <Col span={11}>
            <Flex gap={10} justify="flex-end" align="center">
              <Flex vertical>
                <DisplayText value={homeTeam.name.toUpperCase()} size="title" weight="semiBold" color={colors.white} />
                <Flex gap={5} justify="flex-end" align="center">
                  {homeMatches.map(m => (
                    <ResultBadge key={m.id} match={m} team={homeTeam} size={10} showLetter={false} />
                  ))}
                  <FlashingCircle />
                </Flex>
              </Flex>
              <Avatar size={70} src={homeTeam.logoUrl} />
            </Flex>
          </Col>

          <Col span={2}>
            <Flex vertical>
              <Flex justify='center' align="center" gap={5}>
                <DisplayText value={match.homeGoals ?? '-'} size="giant" weight="semiBold" color={colors.white} />
                <DisplayText value=":" size="giant" weight="semiBold" color={colors.white} />
                <DisplayText value={match.awayGoals ?? '-'} size="giant" weight="semiBold" color={colors.white} />
              </Flex>
            </Flex>
            <Flex justify='center'>
              <DisplayText value={matchStatusMap[match.status]} uppercase size="normal" weight="semiBold" color={colors.white} />
            </Flex>
          </Col>

          <Col span={11}>
            <Flex gap={10} justify="flex-start" align="center">
              <Avatar size={70} src={awayTeam.logoUrl} />
              <Flex vertical>
                <DisplayText value={awayTeam.name.toUpperCase()} size="title" weight="semiBold" color={colors.white} />
                <Flex gap={5}>
                  <FlashingCircle />
                  {awayMatches.map(m => (
                    <ResultBadge key={m.id} match={m} team={awayTeam} size={10} showLetter={false} />
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </Container>
    </ImageBackground>
  );
};

const ImageBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 38, 28, 0.6);
  background-image: url(${image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 30px 0;
`;

const TimeContainer = styled(Flex)`
  margin-top: 20px;
`;

export default MatchHeader;
