import React, { FC } from 'react';
import { Container, DisplayDate, DisplayText, Table } from '../../../components';
import { Match } from '../../../models';
import { useNavigate } from 'react-router-dom';
import { useMatchDetailsService } from '../MatchDetailsService';
import { ColumnsType } from 'antd/es/table';
import { Avatar, Flex } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const OtherMatches: FC = () => {
  const { matchesList, matchDetails } = useMatchDetailsService();
  const navigate = useNavigate();

  if (!matchDetails) return <></>;

  const { match } = matchDetails;
  const matches = matchesList.filter(m => m.leagueId === match.leagueId && m.id !== match.id);
  const columns: ColumnsType<Match> = [
    {
      key: 'date',
      render: (row: Match) => <DisplayDate date={row.scheduledAt} customFormat="DD MMMM YYYY" />,
      width: 190,
    },
    {
      key: 'homeLogo',
      render: (row: Match) => <Avatar src={row.home.logoUrl} />,
      width: 50,
    },
    {
      key: 'awayLogo',
      render: (row: Match) => <Avatar src={row.away.logoUrl} />,
      width: 50,
    },
    {
      key: 'teams',
      render: (row: Match) => (
        <Flex vertical>
          <DisplayText value={row.home.name} />
          <DisplayText value={row.away.name} />
        </Flex>
      ),
    },
    {
      key: 'redirect',
      render: () => <RightOutlined color="#00261c" />,
    },
  ];

  return (
    <Container bordered innerSpace={15}>
      <Table
        columns={columns}
        data={matches}
        pagination={false}
        showHeader={false}
        rowKey="id"
        cellPadding={6}
        onRow={(x: Match) => ({
          onClick: () => {
            navigate(`/matches/${x.id}`);
            window.scrollTo(0, 0);
          },
        })}
      />
    </Container>
  );
};

export default OtherMatches;
