import React from 'react';
import { AggregatedResearchPrediction } from '../models';
import { useApi } from './api';

export function useMonitoringApi() {
  const { api } = useApi();

  const getAggregatedResearchPrediction = React.useCallback(async () => {
    return await api<null, AggregatedResearchPrediction[]>('GET', 'monitoring/aggregated-research-predictions');
  }, [api]);

  return {
    getAggregatedResearchPrediction,
  };
}
