import React, { FC } from 'react';
import { MatchDetails, Position, Team } from '../../../models';
import { ColumnsType } from 'antd/es/table';
import { Container, DisplayText, Table } from '../../../components';

interface Props {
  matchDetails: MatchDetails;
}

const TournamentPreview: FC<Props> = ({ matchDetails }) => {
  const {
    match,
    tournament: { positions },
  } = matchDetails;

  const columns: ColumnsType<Position> = [
    {
      key: 'id',
      title: '#',
      dataIndex: 'id',
      align: 'center',
      render: (id: string) => <DisplayText value={id} textAlign="center" />,
    },
    {
      key: 'team',
      title: 'Team',
      dataIndex: 'team',
      align: 'center',
      render: (team: Team) => (
        <Container width={170}>
          <DisplayText value={team.name} overflow textAlign="center" />
        </Container>
      ),
    },
    {
      key: 'matches',
      title: 'M',
      dataIndex: 'games',
      align: 'center',
      render: (games: number) => <DisplayText value={games} textAlign="center" />,
    },
    {
      key: 'points',
      title: 'P',
      dataIndex: 'points',
      align: 'center',
      render: (points: number) => <DisplayText value={points} textAlign="center" />,
    },
  ];

  return (
    <Container bordered innerSpace={15}>
      <Table
        showHeader
        pagination={false}
        rowHighlight={(p: Position) => p.teamId === match.home.id || p.teamId === match.away.id}
        columns={columns}
        data={positions}
        rowKey={'id'}
        cellPadding={4}
        collapsible
      />
    </Container>
  );
};

export default TournamentPreview;
