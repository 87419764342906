export const fontSizes: { [key: string]: string } = {
  extraSmall: '12px',
  small: '14px',
  normal: '16px',
  sectionTitle: '20px',
  extraLarge: '24px',
  title: '30px',
  giant: '36px',
  hero: '42px',
};

export type FontSize = 'extraSmall' | 'small' | 'normal' | 'sectionTitle' | 'extraLarge' | 'title' | 'giant' | 'hero';
