import React, { FC } from 'react';
import { Flex } from 'antd';
import { Recommendation } from '../../../models';
import { DisplayText, Space, useCreateBetModalService } from '../../../components';
import { useMatchesListService } from '../MatchesListService';
import RecommendationCard from './RecommendationCard';

const Recommendations: FC = () => {
  const { recommendations } = useMatchesListService();
  const { show } = useCreateBetModalService();

  const onCreateBet = (recommendation: Recommendation) => {
    show(recommendation.prediction.token, recommendation.prediction!.match!.id);
    window.scrollTo(0, 0);
  };

  if (!recommendations || !recommendations.length) return <></>;

  return (
    <Flex vertical align="center">
      <DisplayText value="recommended bets" size="sectionTitle" weight="semiBold" />
      <Space size={20} />
      <Flex justify="space-between" gap={25}>
        {recommendations.length >= 4 && (
          <RecommendationCard recommendation={recommendations[3]!} onCreateBet={onCreateBet} />
        )}
        {recommendations.length >= 2 && (
          <RecommendationCard recommendation={recommendations[1]!} onCreateBet={onCreateBet} />
        )}
        {recommendations.length >= 1 && (
          <RecommendationCard recommendation={recommendations[0]!} onCreateBet={onCreateBet} />
        )}
        {recommendations.length >= 3 && (
          <RecommendationCard recommendation={recommendations[2]!} onCreateBet={onCreateBet} />
        )}
        {recommendations.length === 5 && (
          <RecommendationCard recommendation={recommendations[4]!} onCreateBet={onCreateBet} />
        )}
      </Flex>
    </Flex>
  );
};

export default Recommendations;
