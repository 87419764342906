import React from 'react';
import { useApi } from './api';
import { Bet, CreateBetRequest } from '../models';

export function useBetsApi() {
  const { api } = useApi();

  const getBets = React.useCallback(
    async (status: number, leagueIds: string[], userId: string) => {
      const params = {
        leagueIds: leagueIds.join(','),
        status,
        userId,
      };

      return await api<null, Bet[]>('GET', 'bets', params);
    },
    [api],
  );

  const createBet = React.useCallback(
    (matchId: string, token: string, amount: number, description: string) =>
      api<CreateBetRequest, Bet>('POST', 'bets', null, {
        matchId,
        token,
        amount,
        description,
      }),
    [api],
  );

  const updateBetOdd = React.useCallback((id: string) => api<null, Bet>('PUT', `bets/${id}/odd`), [api]);

  return {
    getBets,
    createBet,
    updateBetOdd,
  };
}
