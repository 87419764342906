export type Dictionary = { [propKey: string]: string };

export const PredictionTokens: Dictionary = {
  Home: 'Home',
  Draw: 'Draw',
  Away: 'Away',
  HomeDraw: 'Home or Draw',
  DrawAway: 'Draw or Away',
  HomeAway: 'Home or Away',
  HomeScored: 'Home scored',
  AwayScored: 'Away scored',
  BothScored: 'Both scored',
  TotalOver15: 'Over 1.5',
  TotalOver25: 'Over 2.5',
  TotalOver35: 'Over 3.5',
  TotalUnder15: 'Under 1.5',
  TotalUnder25: 'Under 2.5',
  TotalUnder35: 'Under 3.5',
  HomeOver15: 'Over 1.5',
  HomeOver25: 'Over 2.5',
  HomeOver35: 'Over 3.5',
  HomeUnder15: 'Under 1.5',
  HomeUnder25: 'Under 2.5',
  HomeUnder35: 'Under 3.5',
  AwayOver15: 'Over 1.5',
  AwayOver25: 'Over 2.5',
  AwayOver35: 'Over 3.5',
  AwayUnder15: 'Under 1.5',
  AwayUnder25: 'Under 2.5',
  AwayUnder35: 'Under 3.5',
};
