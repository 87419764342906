import { FC, ReactNode } from 'react';
import { Injury, InjuryStatus } from '../../../../models';
import { colors } from '../../../../styles';
import { Flex } from 'antd';
import { DisplayText } from '../../../../components';
import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { styled } from 'styled-components';

interface Props {
  injury?: Injury;
}

interface DisplayInjuryProps {
  color?: string;
  icon: ReactNode;
  reason?: string;
}

export const DisplayInjury: FC<Props> = ({ injury }) => {
  const inStatus: DisplayInjuryProps = {
    color: colors.primary,
    icon: <CheckIcon />,
    reason: 'IN',
  };

  const missingStatus: DisplayInjuryProps = {
    color: colors.red,
    icon: <CloseIcon />,
    reason: injury?.reason,
  };

  const questionableStatus: DisplayInjuryProps = {
    color: colors.grey,
    icon: <QuestionIcon />,
    reason: injury?.reason,
  };

  let obj = inStatus;
  if (injury && injury.status === InjuryStatus.Missing) {
    obj = missingStatus;
  } else if (injury && injury.status === InjuryStatus.Questionable) {
    obj = questionableStatus;
  }

  return (
    <Flex justify="center" align="center" gap={5}>
      {obj.icon}
      <DisplayText value={obj.reason} weight="medium" color={obj.color} overflow textAlign="center" />
    </Flex>
  );
};

const CheckIcon = styled(CheckCircleOutlined)`
  font-size: 16px;
  color: ${colors.primary};
`;

const CloseIcon = styled(CloseCircleOutlined)`
  font-size: 16px;
  color: ${colors.red};
`;

const QuestionIcon = styled(QuestionCircleOutlined)`
  font-size: 16px;
  color: ${colors.grey};
`;