import React, { FC } from 'react';
import { Container, DisplayHeader, DisplayText, Space } from '../../../components';
import { Flex } from 'antd';
import { DoughnutChart } from './DoughnutChart';
import { UserStats } from 'src/models';

interface Props {
  userStats: UserStats;
}

export const WinRate: FC<Props> = ({ userStats }) => {
  const sumWLD = userStats.annual.won + userStats.annual.pending + userStats.annual.lost;
  const wonPercent = ((userStats.annual.won * 100) / sumWLD).toFixed(2);
  const lostPercent = ((userStats.annual.lost * 100) / sumWLD).toFixed(2);

  return (
    <Flex vertical>
      <DisplayHeader value="win rate" />
      <Space size={20} />
      <Container width={410} height={210} bordered innerSpace={20}>
        <Flex justify="space-between" align="center">
          <Flex justify="center" align="center" vertical>
            <DisplayText weight="medium" value={`${wonPercent}%`} size="sectionTitle" />
            <DisplayText weight="medium" value="Won" size="sectionTitle" />
          </Flex>

          <Flex align="center">
            <Container height={170}>
              <DoughnutChart />
            </Container>
          </Flex>

          <Flex justify="center" align="center" vertical>
            <DisplayText weight="medium" value={`${lostPercent}%`} size="sectionTitle" />
            <DisplayText weight="medium" value="Lost" size="sectionTitle" />
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
};
