import React, { FC } from 'react';
import { DisplayText, Table, Container } from '../../../components';
import { MatchDetails, Position, Team } from '../../../models';
import { ColumnsType } from 'antd/es/table';
import { colors } from '../../../styles';

const columns: ColumnsType<Position> = [
  {
    key: 'id',
    title: '#',
    dataIndex: 'id',
    align: 'center',
    render: (id: string) => <DisplayText value={id} textAlign="center" />,
  },
  {
    key: 'team',
    title: 'Team',
    dataIndex: 'team',
    width: 270,
    align: 'center',
    render: (team: Team) => <DisplayText value={team.name} textAlign="center" />,
  },
  {
    key: 'matches',
    title: 'M',
    dataIndex: 'games',
    align: 'center',
    render: (games: number) => <DisplayText value={games} textAlign="center" />,
  },
  {
    key: 'wins',
    title: 'W',
    dataIndex: 'wons',
    align: 'center',
    render: (wons: number) => <DisplayText value={wons} color={colors.primary} weight="medium" textAlign="center" />,
  },
  {
    key: 'draws',
    title: 'D',
    dataIndex: 'deals',
    align: 'center',
    render: (deals: number) => <DisplayText value={deals} color={colors.greyText} weight="medium" textAlign="center" />,
  },
  {
    key: 'loses',
    title: 'L',
    dataIndex: 'loses',
    align: 'center',
    render: (loses: number) => <DisplayText value={loses} color={colors.red} weight="medium" textAlign="center" />,
  },
  {
    key: 'goals',
    title: 'G',
    align: 'center',
    render: (row: Position) => <DisplayText value={`${row.scoredGoals}/${row.missedGoals}`} textAlign="center" />,
  },
  {
    key: 'points',
    title: 'P',
    dataIndex: 'points',
    align: 'center',
    render: (points: number) => <DisplayText value={points} textAlign="center" />,
  },
];

interface Props {
  matchDetails: MatchDetails;
}

export const FullTournament: FC<Props> = ({ matchDetails }) => (
  <Container bordered innerSpace={20}>
    <Table
      showHeader
      pagination={false}
      rowHighlight={(p: Position) => p.teamId === matchDetails.match.home.id || p.teamId === matchDetails.match.away.id}
      columns={columns}
      data={matchDetails.tournament.positions}
      rowKey="teamId"
      cellPadding={8}
    />
  </Container>
);
