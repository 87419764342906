import React, { FC } from 'react';
import { Container, DisplayDecimal, DisplayHeader, DisplayText, Space } from '../../../components';
import { Flex } from 'antd';
import dayjs from 'dayjs';
import { Area, AreaChart, XAxis, CartesianGrid, YAxis, Tooltip, TooltipProps } from 'recharts';
import { colors } from '../../../styles';
import { styled } from 'styled-components';
import { UserStats } from '../../../models';

interface Props {
  userStats: UserStats;
}

interface StatWithBalance {
  date: string;
  roi: number; 
  balance: number;
}

export const ProfitChart: FC<Props> = ({ userStats }) => {
  let stat: { date: string; roi: number }[] = userStats.monthBetStats
    .map(x => ({ date: dayjs(x.date), roi: x.stats.roi }))
    .sort((a, b) => dayjs(a.date).diff(b.date))
    .map(x => ({ date: dayjs(x.date).format('MMM YY'), roi: x.roi }));

  let balance = 1000;
  let statWithBalance: StatWithBalance[] = stat.map(i => {
    balance = balance + i.roi;
    return {
      ...i,
      balance: balance,
    };
  });

  return (
    <Flex vertical>
      <DisplayHeader value="profit chart" />
      <Space size={20} />
      <Container width={410} height={210} bordered innerSpace={10}>
        <AreaChart width={380} height={190} data={statWithBalance}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={renderTooltip} />
          <Area type="monotone" dataKey="balance" stroke={colors.primary} fill={colors.primaryLight} fillOpacity={0.5} />
        </AreaChart>
      </Container>
    </Flex>
  );
};

const renderTooltip = ({ payload }: TooltipProps<number, string>) => {
  if (payload && payload.length) {
    const { date, balance } = payload[0]!.payload;
    return (
      <TooltipContainer>
        <Space size={10} />
        <Flex vertical align="center" justify="center" gap={10}>
          <DisplayText value={date} />
          <DisplayDecimal value={+balance} color={colors.primary} />
        </Flex>
      </TooltipContainer>
    );
  } else {
    return <></>;
  }
}

const TooltipContainer = styled.div`
  background-color: ${colors.white};
  height: 70px;
  width: 70px;
  border: 1px solid ${colors.grey};
`;
