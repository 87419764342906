export enum StreakToken {
  ScoredGoal = 'ScoredGoal',
  MissedGoal = 'MissedGoal',
  CleanSheet = 'CleanSheet',
  NoLoses = 'NoLoses',
  NoDraws = 'NoDraws',
  NoWins = 'NoWins',
  ScoredTwoMore = 'ScoredTwoMore',
  MissedTwoMore = 'MissedTwoMore',
  BothScored = 'BothScored',
}
