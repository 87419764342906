export function groupBy<T>(array: T[], key: keyof T): { [key: string]: T[] } {
  const result: { [key: string]: T[] } = {};
  for (const item of array) {
    const groupKey = item[key] as string;
    if (!result[groupKey]) {
      result[groupKey] = [];
    }

    result[groupKey]!.push(item);
  }

  return result;
}
