import React, { FC } from 'react';
import { Image } from 'antd';
import styled from 'styled-components';

interface Props {
  name: string;
  countryFlag: string;
}

export const LeagueLabel: FC<Props> = ({ name, countryFlag }) => (
  <div>
    <Image preview={false} width={20} height={20} src={countryFlag} />
    <LeaguesFilterItemLabel>{name}</LeaguesFilterItemLabel>
  </div>
);

const LeaguesFilterItemLabel = styled.label`
  padding-left: 5px;
`;
