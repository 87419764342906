import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Modal } from 'antd';
import { useLoginModalService } from './LoginModalService';
import { useSignUpModalService } from '../SignUpModal';

export const LoginModal: FC = () => {
  const { display, hide, login } = useLoginModalService();
  const { show } = useSignUpModalService();
  const [form] = Form.useForm();

  const onClearAndClose = () => {
    form.resetFields();
    hide();
  };

  const onClearAndRedirectToSignup = () => {
    form.resetFields();
    hide();
    show();
  };

  return (
    <Modal open={display} footer={null} onCancel={onClearAndClose} closable={true}>
      <Title>Login</Title>
      <Form form={form} labelCol={{ offset: 2, span: 6 }} wrapperCol={{ span: 12 }} onFinish={login}>
        <Form.Item
          label="Username"
          name="username"
          colon={true}
          rules={[{ required: true, message: 'Username is required' }]}>
          <Input placeholder="Enter username" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Password is required',
            },
            {
              min: 8,
              message: 'The minimum length of password is 8 symbols',
            },
            {
              pattern: new RegExp('.*[A-Z].*'),
              message: 'Password should contain at least one capital letter',
            },
          ]}>
          <Input.Password placeholder="Enter passsword" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 11, span: 2 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
          <Button type="link" onClick={onClearAndRedirectToSignup}>
            Don't have an account? Register
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Title = styled.h1`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;
