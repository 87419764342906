import React from 'react';
import { useApi } from './api';
import { Match, MatchDetails, MatchVote, GetMatchPlayersResponse, UpdateMatchVoteRequest } from '../models';
import dayjs from 'dayjs';

export function useMatchesApi() {
  const { api } = useApi();

  const getMatch = React.useCallback(
    async (id: string) => {
      return await api<null, MatchDetails>('GET', `matches/${id}`, null);
    },
    [api],
  );

  const getMatches = React.useCallback(
    async (leagueIds: string[], date: string, numberOfDays: number) => {
      const params = {
        leagueIds: leagueIds.join(','),
        from: dayjs(date).startOf('day').toISOString(),
        to: dayjs(date).startOf('day').add(numberOfDays, 'day').add(-1, 'second').toISOString(),
      };

      return await api<null, Match[]>('GET', 'matches', params);
    },
    [api],
  );

  const updateMatchVote = React.useCallback(
    async (id: string, type: string) => {
      return await api<UpdateMatchVoteRequest, MatchVote>('PUT', `matches/${id}/match-vote`, null, { type: type });
    },
    [api],
  );

  const getPlayers = React.useCallback(
    async (id: string) => {
      return await api<null, GetMatchPlayersResponse>('GET', `matches/${id}/players`, null);
    },
    [api],
  );

  return {
    getMatch,
    getMatches,
    updateMatchVote,
    getPlayers,
  };
}
