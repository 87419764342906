import React, { FC } from 'react';
import { Avatar, Flex } from 'antd';
import {
  Container,
  DisplayDate,
  DisplayPercentage,
  DisplayText,
  DisplayGoals,
  Space,
  Table,
} from '../../../components';
import { HeadToHeadMatch, Team } from '../../../models';
import { styled } from 'styled-components';
import { colors } from '../../../styles';
import { ColumnsType } from 'antd/es/table';

interface HeadToHeadMatchesProps {
  matches: HeadToHeadMatch[];
  homeTeam: Team;
  awayTeam: Team;
}

const HeadToHeadMatches: FC<HeadToHeadMatchesProps> = ({ matches, homeTeam, awayTeam }) => {
  // if (matches.length === 0) return <NoHeadToHeadMatches homeTeam={homeTeam} awayTeam={awayTeam} />;

  let homeWin = 0;
  let draw = 0;
  let awayWin = 0;

  matches?.forEach(match => {
    if (match.home.name === homeTeam.name) {
      if (match.homeGoals! > match.awayGoals!) {
        homeWin += 1;
      } else if (match.homeGoals! < match.awayGoals!) {
        awayWin += 1;
      } else {
        draw += 1;
      }
    } else if (match.home.name === awayTeam.name) {
      if (match.homeGoals! > match.awayGoals!) {
        awayWin += 1;
      } else if (match.homeGoals! < match.awayGoals!) {
        homeWin += 1;
      } else {
        draw += 1;
      }
    }
  });

  let sum = homeWin + draw + awayWin;

  const columns: ColumnsType<HeadToHeadMatch> = [
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'scheduledAt',
      width: 150,
      render: (scheduledAt: string) => <DisplayDate date={scheduledAt!} customFormat="DD MMMM YYYY" />,
    },
    {
      key: 'league',
      title: 'League',
      dataIndex: 'league',
      width: 100,
      render: (league: string) => <DisplayText value={league} overflow />,
    },
    {
      key: 'teams',
      title: 'Teams',
      render: (row: HeadToHeadMatch) => (
        <Flex vertical gap={5}>
          <Flex gap={5}>
            <Avatar size={23} src={row.home.logoUrl} />
            <DisplayText value={row.home.name} />
          </Flex>

          <Flex gap={5}>
            <Avatar size={23} src={row.away.logoUrl} />
            <DisplayText value={row.away.name} />
          </Flex>
        </Flex>
      ),
    },
    {
      key: 'score',
      title: 'Score',
      width: 50,
      render: (row: HeadToHeadMatch) => (
        <Flex vertical gap={5} align="center">
          <DisplayGoals value={row.homeGoals} isHomeTeam />
          <DisplayGoals value={row.awayGoals} />
        </Flex>
      ),
    },
  ];

  return (
    <Container bordered innerSpace={15}>
      <Flex justify="center">
        <DisplayText value={`${homeTeam.name} - ${awayTeam.name}`} size="sectionTitle" />
      </Flex>

      <Space size={15} />

      {sum === 0 ? (
        <Flex justify="center">
          <Line $color={colors.grey} $width={100} />
        </Flex>
      ) : (
        <Flex justify="center">
          <Line $color={colors.primaryLight} $width={sum ? (100 / sum) * homeWin : 0} />
          <Line $color={colors.grey} $width={sum ? (100 / sum) * draw : 0} />
          <Line $color={colors.blue} $width={sum ? (100 / sum) * awayWin : 0} />
        </Flex>
      )}

      <Space size={15} />

      <Flex justify="space-between">
        <Flex>
          <Avatar size={40} src={homeTeam.logoUrl} />
          <Flex vertical justify="center">
            <DisplayPercentage
              value={sum ? homeWin / sum : 0}
              size="sectionTitle"
              weight="medium"
              color={colors.black}
            />
            <DisplayText value={`${homeWin} Win(s)`} />
          </Flex>
        </Flex>

        <Flex vertical justify="center">
          <DisplayPercentage value={sum ? draw / sum : 0} size="sectionTitle" weight="medium" color={colors.black} />
          <DisplayText value={`${draw} Draw(s)`} />
        </Flex>

        <Flex>
          <Flex vertical justify="center">
            <DisplayPercentage
              value={sum ? awayWin / sum : 0}
              size="sectionTitle"
              weight="medium"
              color={colors.black}
            />
            <DisplayText value={`${awayWin} Win(s)`} />
          </Flex>
          <Avatar size={40} src={awayTeam.logoUrl} />
        </Flex>
      </Flex>

      <Space size={15} />

      <Table columns={columns} data={matches} showHeader={false} pagination={false} rowKey="id" cellPadding={6} />
    </Container>
  );
};

export const Line = styled.div<{ $width: number; $color?: string }>`
  height: 10px;
  border-radius: 13px;
  background-color: ${props => props.$color};
  width: ${props => props.$width}%;
  margin-right: 5px;
`;

export default HeadToHeadMatches;
