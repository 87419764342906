import React, { FC } from 'react';
import { Flex } from 'antd';
import { styled } from 'styled-components';
import { colors } from '../styles';
import { DisplayText } from './index';

interface Props {
  token: string;
  value?: number;
  odd: number;
}

export const PredictionLabel: FC<Props> = ({ token, value, odd }) => {
  const leftValue = value ? `${value}%` : '';
  const rightValue = odd ?? '';
  const ResultValue = leftValue && rightValue ? `${leftValue} / ${rightValue}` : `${leftValue}${rightValue}`;
  return (
    <BorderedSpace gap="small" align="center">
      <DisplayText value={token} size="normal" weight="normal" />
      <ValueContainer $value={value}>
        <DisplayText value={ResultValue} size="normal" weight="normal" />
      </ValueContainer>
    </BorderedSpace>
  );
};

const BorderedSpace = styled(Flex)`
  padding: 5px 15px;
  border: 2px solid ${colors.grey};
  border-radius: 13px;
`;

const ValueContainer = styled.div<{ $value?: number }>`
  background-color: ${props => {
    if (!props.$value) return colors.greyLight;
    if (props.$value < 75) return colors.redLight;
    if (props.$value < 85) return colors.grey;
    return colors.primaryLight;
  }};
  border-radius: 5px;
  padding: 3px 5px;
`;
