import React, { FC } from 'react';
import styled from 'styled-components';
import { Form, Input, Button, Modal } from 'antd';
import { useConfirmCodeModalService } from './ConfirmCodeModalService';

export const ConfirmCodeModal: FC = () => {
  const { display, hide, resendCode, verify } = useConfirmCodeModalService();
  const [form] = Form.useForm();

  const onClearAndClose = () => {
    form.resetFields();
    hide();
  };

  return (
    <Modal open={display} footer={null} onCancel={onClearAndClose} closable={true}>
      <Container>
        <Form form={form} labelCol={{ offset: 2, span: 6 }} wrapperCol={{ span: 12 }} onFinish={verify}>
          <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
            <span>Confirmation code has been sent to your email address.</span>
          </Form.Item>

          <Form.Item label="Code" name="code" colon={true} rules={[{ required: true, message: 'Code is required' }]}>
            <Input placeholder="Enter confirmation code" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 11, span: 2 }}>
            <Button type="primary" htmlType="submit">
              Confirm
            </Button>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 10 }}>
            <Button type="link" onClick={resendCode}>
              Resend Confirmation Code
            </Button>
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  padding-top: 10px;
  width: 500px;
  border-radius: 10px;
`;
