import { colors } from 'src/styles';
import styled, { keyframes } from 'styled-components';

const flash = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

export const FlashingCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${colors.white};
  animation: ${flash} 1s infinite;
`;