import React, { FC } from 'react';
import { Avatar, Flex, Image } from 'antd';
import { Container, DisplayText } from '../../../components';
import { MatchDetails } from 'src/models';
import dotImg from '../../../assets/icons/dotLocation.svg';

interface Props {
  matchDetails: MatchDetails;
}

const MatchInfo: FC<Props> = ({ matchDetails }) => {
  const {
    match: { stadium, city, countryFlag },
  } = matchDetails;

  return (
    <Container bordered innerSpace={15}>
      <Flex vertical gap={20}>
        <Flex vertical gap={10} align="center">
          <Flex gap={10} align="center" justify="center" wrap="wrap">
            <Avatar size={30} src={countryFlag} />
            <DisplayText value={city} overflow textAlign="center" />
          </Flex>
        </Flex>

        <Flex gap={10} justify="center" align="center">
          <Image src={dotImg} />
          <DisplayText value={stadium} overflow textAlign="center" />
        </Flex>
      </Flex>
    </Container>
  );
};

export default MatchInfo;
