import React from 'react';
import { useApi } from './api';
import { League, UpdateFavouriteLeagueRequest, LeagueBasicStats } from '../models';

export function useLeaguesApi() {
  const { api } = useApi();

  const getLeagues = React.useCallback(
    (onlyEnabled: boolean) => api<null, League[]>('GET', 'leagues', { onlyEnabled }),
    [api],
  );

  const updateFavouriteLeague = React.useCallback(
    async (id: string, favourite: boolean) =>
      api<UpdateFavouriteLeagueRequest, League>('PUT', `leagues/${id}/favourite`, null, { favourite: favourite }),
    [api],
  );

  const getLeagueBasicStats = React.useCallback(
    (id: string) => api<null, LeagueBasicStats>('GET', `leagues/${id}/basic-stats`, null),
    [api],
  );

  return {
    getLeagues,
    updateFavouriteLeague,
    getLeagueBasicStats,
  };
}
