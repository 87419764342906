import { useBetsApi, useLeaguesApi } from '../../api';
import { useQuery } from '@tanstack/react-query';
import { BetFilterStatuses, BetsGridConfig } from '../../models';
import { create } from 'zustand';

interface State {
  config: BetsGridConfig;
  setConfig(config: BetsGridConfig): void;
}

const useStore = create<State>()(set => ({
  config: {
    leagueIds: [],
    userIds: [],
    status: BetFilterStatuses.Pending,
  },
  setConfig: config => set(state => ({ ...state, config })),
}));

export function useBetsListService() {
  const { getBets } = useBetsApi();
  const { getLeagues } = useLeaguesApi();
  const { config, setConfig } = useStore();

  const bets = useQuery({
    queryKey: ['bets', config],
    queryFn: () => getBets(config.status, config.leagueIds, ''),
  });

  const leagues = useQuery({
    queryKey: ['leagues'],
    queryFn: () => getLeagues(true),
  });

  return {
    bets: bets.data ?? [],
    leagues: leagues.data ?? [],
    loading: bets.isFetching || leagues.isFetching,
    config,
    setConfig,
  };
}
