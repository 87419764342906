import React, { FC } from 'react';
import { Table as AntdTable, Button, Flex } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { styled } from 'styled-components';
import { colors } from '../styles';
import { GetRowKey } from 'antd/es/table/interface';
import { NoItems } from './NoItems';
import { DisplayText, Space } from './index';

interface Props {
  columns: ColumnsType<any>;
  data: any[];
  showHeader?: boolean;
  pagination: false | TablePaginationConfig;
  rowKey: string | GetRowKey<unknown>;
  noDataText?: string;
  cellPadding?: number;
  onRow?: (value: any, index: number) => React.HTMLAttributes<any>;
  rowHighlight?: (value: any) => boolean;
  showVerticalBorders?: boolean;
  bordered?: boolean;
  collapsible?: boolean;
}

export const Table: FC<Props> = ({
  columns,
  showHeader,
  bordered,
  data,
  pagination,
  rowKey,
  noDataText = 'No data',
  cellPadding = 16,
  onRow,
  rowHighlight,
  showVerticalBorders = false,
  collapsible = false,
}) => {
  const [showAllRows, setShowAllRows] = React.useState(false);
  const highlightedData = data.filter(row => rowHighlight && rowHighlight(row));
  const displayedData = collapsible && !showAllRows ? highlightedData : data;

  const onRowClassName = (record: any) => {
    if (rowHighlight && rowHighlight(record)) {
      return 'highlighted-row';
    }

    return '';
  };

  if (!data || data.length === 0) {
    return <NoItems value={noDataText} width={0} />;
  }

  return (
    <>
      <StyledTable
        rowKey={rowKey}
        showHeader={showHeader}
        pagination={pagination}
        columns={columns}
        dataSource={displayedData}
        rowClassName={onRowClassName}
        onRow={onRow}
        $cellPadding={cellPadding}
        $showVerticalBorders={showVerticalBorders}
        bordered={bordered}
      />
      {collapsible && data.length > highlightedData.length && (
        <>
          <Space size={15} />
          <Flex justify="center">
            <Button onClick={() => setShowAllRows(!showAllRows)}>
              <DisplayText value={showAllRows ? 'Hide' : 'Show All'} weight="medium" uppercase />
            </Button>
          </Flex>
        </>
      )}
    </>
  );
};

export const StyledTable = styled(AntdTable)<{ $cellPadding: number; $showVerticalBorders: boolean }>`
  tbody {
    tr:last-child {
      td {
        border-bottom: none !important;
      }
    }

    tr {
      td:last-child {
        border-right: none !important;
      }

      td:first-child {
        border-bottom: 3px solid ${colors.white};
      }

      &:hover {
        cursor: pointer;
        background: none;

        td {
          border-bottom: 3px solid ${colors.primary};
        }
      }

      td {
        white-space: nowrap;
        border-bottom: 1px solid ${colors.borderColor} !important;
        padding: ${props => props.$cellPadding}px !important;
        border-right: ${props => props.$showVerticalBorders && `1px solid ${colors.borderColor} !important`};
      }
    }
  }

  thead {
    tr,
    th {
      border-bottom: none !important;
      padding: ${props => props.$cellPadding}px !important;
    }
  }

  .highlighted-row {
    td {
      background-color: ${colors.primaryLight};
    }

    td:first-child {
      border-left: 3px solid ${colors.primary};
    }
  }
`;
