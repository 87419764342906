import React, { FC, PropsWithChildren } from 'react';
import { Flex } from 'antd';
import { styled } from 'styled-components';
import { colors } from '../../../styles';
import { DisplayText } from '../../../components';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

export interface Props {
  header: string;
  collapsed: boolean;
}

export const BetGroupContainer: FC<PropsWithChildren<Props>> = ({ header, collapsed, children }) => {
  const [expand, setExpand] = React.useState<boolean>(collapsed);
  return (
    <Flex vertical>
      <BetsBar justify="space-between" align="center">
        <DisplayText value={header} weight="medium" />
        {expand ? (
          <MinusIcon onClick={() => setExpand(!expand)} />
        ) : (
          <PlusIcon onClick={() => setExpand(!expand)} />
        )}
      </BetsBar>
      <Bets $display={expand}>{children}</Bets>
    </Flex>
  );
};

const BetsBar = styled(Flex)`
  width: 100%;
  height: 45px;
  background-color: ${colors.greyLight};
  border-radius: 11px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0 15px;
`;

const Bets = styled.div<{
  $display: boolean;
}>`
  ${props =>
    props.$display === false &&
    `
    display: none;
  `}
`;

const MinusIcon = styled(MinusOutlined)`
  font-size: 15px;
  color: ${colors.black};
`;

const PlusIcon = styled(PlusOutlined)`
  font-size: 15px;
  color: ${colors.black};
`;