import React, { FC } from 'react';
import { useMatchDetailsService } from '../MatchDetailsService';
import { Container, DisplayText } from '../../../components';
import { Cell, Pie, PieChart, PieLabel, ResponsiveContainer, Text } from 'recharts';
import { colors } from '../../../styles';
import { Flex } from 'antd';
import { styled } from 'styled-components';

const LeagueBasicStats: FC = () => {
  const { leagueBasicStats, matchDetails } = useMatchDetailsService();

  if (!leagueBasicStats || !matchDetails) return <></>;

  const league = matchDetails!.match.league;

  const data = [
    {
      key: 'Home',
      value: leagueBasicStats.home,
      color: colors.primaryLight,
    },
    {
      key: 'Draw',
      value: leagueBasicStats.draw,
      color: colors.grey,
    },
    {
      key: 'Away',
      value: leagueBasicStats.away,
      color: colors.redLight,
    },
  ];

  const renderLabel: PieLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <StyledText x={x} y={y} fill="black" textAnchor="middle" dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </StyledText>
    );
  };

  return (
    <Container bordered innerSpace={15}>
      <Flex vertical gap={10} align="center">
        <DisplayText weight="semiBold" size="sectionTitle" value={league.toUpperCase()} textAlign="center" />
        <DisplayText value="Last 5 years league results" />
        <ResponsiveContainer height={200} width={200}>
          <PieChart width={200} height={200}>
            <Pie data={data} cx={100} cy={100} labelLine={false} label={renderLabel} outerRadius={80} dataKey={'value'}>
              {data.map(({ color }) => (
                <Cell key={color} fill={color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Flex>
    </Container>
  );
};

const StyledText = styled(Text)`
  font-weight: 700;
  font-size: 18px;
`;

export default LeagueBasicStats;
