import React, { FC } from 'react';
import { Container, DisplayText, Loading, NavTab, Space } from '../../components';
import { Flex, Menu, MenuProps } from 'antd';
import { useProfileService } from './ProfileService';
import { Outlet } from 'react-router-dom';
import { TopForecasters, UserInfo } from './components';
import { styled } from 'styled-components';
import { colors } from '../../styles';

const items: MenuProps['items'] = [
  {
    label: <NavTab to="">PROFILE PREVIEW</NavTab>,
    key: '',
  },
  {
    label: <NavTab to="bets">BETS</NavTab>,
    key: 'bets',
  },
  {
    label: <NavTab to="profile-statistics">PROFILE STATISTICS</NavTab>,
    key: 'profile-statistics',
  },
];

export const ProfileScreen: FC = () => {
  const { loading } = useProfileService();
  const { annualTop, monthTop } = useProfileService();
  const [selectedKey, setSelectedKey] = React.useState('');

  const handleClickTab: MenuProps['onClick'] = e => {
    setSelectedKey(e.key);
  };

  return (
    <Loading loading={loading}>
      <UserInfo />
      <StyledMenu selectedKeys={[selectedKey]} onClick={handleClickTab} mode="horizontal" items={items} />

      <Space size={30} />

      <Container width={1200}>
        <Flex gap={20}>
          <Container width={840}>
            <Outlet />
          </Container>

          <Container width={340}>
            <Flex vertical={true} gap={20}>
              <Flex vertical gap={20}>
                <DisplayText value="TOP Forecasters This Month" size="sectionTitle" weight="semiBold" />
                <TopForecasters users={monthTop} />
              </Flex>

              <Flex vertical gap={20}>
                <DisplayText value="TOP Forecasters All Time" size="sectionTitle" weight="semiBold" />
                <TopForecasters users={annualTop} />
              </Flex>
            </Flex>
          </Container>
        </Flex>
      </Container>

      <Space size={30} />
    </Loading>
  );
};

const StyledMenu = styled(Menu)`
  background-color: ${colors.secondaryDark};
  height: 56px;
  padding: 5px 0 0 150px;
  position: sticky;
  z-index: 100;
  top: 0px;
`;
