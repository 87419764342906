import React, { FC } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useProfileService } from '../ProfileService';
import { colors } from 'src/styles';

ChartJS.register(ArcElement, Tooltip, Legend);

export const DoughnutChart: FC = () => {
  const { userStats } = useProfileService();
  const data = {
    datasets: [
      {
        data: [userStats?.annual.won ?? 0, userStats?.annual.pending ?? 0, userStats?.annual.lost ?? 0],
        backgroundColor: [colors.primaryLight, colors.grey, colors.redLight],
        borderWidth: 2,
        radius: '90%',
      },
    ],
  };

  return <Doughnut data={data} />;
};
