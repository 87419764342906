import React, { FC } from 'react';
import { Flex, Row } from 'antd';
import { Prediction } from '../../../models';
import { DisplayText } from 'src/components';
import { colors } from 'src/styles';
import { styled } from 'styled-components';

interface Props {
  prediction: Prediction;
  onClick: () => void;
}

const getColor = (value: number) => {
  if (value < 75) return colors.red;
  if (value < 85) return colors.greyText;
  return colors.primary;
};

const GridPrediction: FC<Props> = ({ prediction: { value, token, odd }, onClick }) => {
  return (
    <Flex vertical align='center' onClick={onClick}>
      <Row>
        <Flex align='center' gap={3}>
          <DisplayText value={token} />
          {
            value && 
              <>
                <DisplayText value="-" />
                <DisplayText value={`${value}%`} color={getColor(value)} weight='medium'  />
              </>
          }
        </Flex>
      </Row>
      <Row>
        {odd && (
          <PredictionValue>
            <DisplayText value={odd} />
          </PredictionValue>
        )}
      </Row>
    </Flex>
  );
};

const PredictionValue = styled.div`
  border: 2px solid ${colors.grey};
  border-radius: 4px;
  margin: 5px 0;
  padding: 1px 3px;
`;

export default GridPrediction;
