import { Flex } from 'antd';
import React, { FC } from 'react';
import { Container, DisplayHeader, Space } from '../../../components';
import MatchInfo from './MatchInfo';
import WhoWillWin from './WhoWillWin';
import TournamentPreview from './TournamentPreview';
import { useMatchDetailsService } from '../MatchDetailsService';
import HeadToHeadMatches from './HeadToHeadMatches';
import LastMatchesList from './LastMatchesList';
import OtherMatches from './OtherMatches';
import LeagueBasicStats from './LeagueBasicStats';
import dayjs from 'dayjs';

export const MatchPreview: FC = () => {
  const { matchDetails } = useMatchDetailsService();
  if (!matchDetails) return <></>;

  return (
    <Flex justify="center" gap={20}>
      <Container width={285}>
        <Flex vertical>
          <Flex vertical>
            <DisplayHeader value="match info" />
            <Space size={20} />
            <MatchInfo matchDetails={matchDetails} />
          </Flex>
        </Flex>

        <Space size={20} />
        <WhoWillWin matchDetails={matchDetails} />

        <Space size={20} />
        <TournamentPreview matchDetails={matchDetails} />

        <Space size={20} />
        <LeagueBasicStats />
      </Container>

      <Container width={615}>
        <Flex vertical>
          <DisplayHeader value="head-to-head matches" />
          <Space size={20} />
          <HeadToHeadMatches
            matches={matchDetails!.headToHeadMatches
              .sort((a, b) => (dayjs(b.scheduledAt).isAfter(a.scheduledAt) ? 1 : -1))
              .slice(0, 5)}
            homeTeam={matchDetails!.match.home}
            awayTeam={matchDetails!.match.away}
          />
        </Flex>

        <Space size={20} />

        <Flex vertical>
          <DisplayHeader value="last matches" />
          <Space size={20} />
          <Flex gap={15}>
            <Container width={300}>
              <LastMatchesList
                matches={matchDetails.homeTeamMatches.slice(0, 5)}
                team={matchDetails.match.home}
                color="primaryLight"
              />
            </Container>
            <Container width={300}>
              <LastMatchesList
                matches={matchDetails.awayTeamMatches.slice(0, 5)}
                team={matchDetails.match.away}
                color="blue"
              />
            </Container>
          </Flex>
        </Flex>

        <Space size={20} />

        <Flex vertical>
          <DisplayHeader value={`other matches in ${matchDetails.match.league}`} />
          <Space size={20} />
          <OtherMatches />
        </Flex>
      </Container>
    </Flex>
  );
};
