import React, { FC } from 'react';
import { Flex } from 'antd';
import { DisplayDecimal, DisplayPercentage, DisplayText, DisplayDate, Table, Container } from '../../../components';
import { MonthlyUserStatistics, UserStats } from '../../../models';
import { ColumnsType } from 'antd/es/table';
import { colors } from '../../../styles';

interface Props {
  userStats: UserStats;
}

export const Performance: FC<Props> = ({ userStats }) => {
  const monthlyStats = userStats.monthBetStats.filter(x => x.stats.lost + x.stats.won > 0);

  const columns: ColumnsType<MonthlyUserStatistics> = [
    {
      key: 'date',
      title: 'Month',
      dataIndex: 'date',
      align: 'center',
      width: 150,
      render: (date: string) => <DisplayDate date={date} customFormat="MMMM YYYY" weight="medium" textAlign="center" />,
    },
    {
      key: 'yield',
      title: 'Yield',
      align: 'center',
      render: (row: MonthlyUserStatistics) => (
        <DisplayPercentage
          value={row.stats.yield}
          weight="medium"
          color={row.stats.yield >= 0 ? colors.primary : colors.red}
          textAlign="center"
        />
      ),
    },
    {
      key: 'bets',
      title: 'Bets',
      align: 'center',
      render: ({ stats: { won, pending, lost } }: MonthlyUserStatistics) => (
        <DisplayText value={won + pending + lost} weight="medium" textAlign="center" />
      ),
    },
    {
      key: 'wld',
      title: 'W/L/D',
      align: 'center',
      render: ({ stats: { won, pending, lost } }: MonthlyUserStatistics) => (
        <Flex align="center" justify="center">
          <DisplayText value={`${won}/`} weight="medium" color={colors.primary} />
          <DisplayText value={`${pending}/`} weight="medium" color={colors.greyText} />
          <DisplayText value={lost} weight="medium" color={colors.red} />
        </Flex>
      ),
    },
    {
      key: 'averageOdd',
      title: 'Average Odd',
      align: 'center',
      render: ({ stats: { averageOdd } }: MonthlyUserStatistics) => (
        <DisplayDecimal value={averageOdd} weight="medium" textAlign="center" />
      ),
    },
    {
      key: 'averageBet',
      title: 'Average Bet',
      align: 'center',
      render: ({ stats: { averageBet } }: MonthlyUserStatistics) => (
        <DisplayDecimal value={averageBet} weight="medium" textAlign="center" showCurrency />
      ),
    },
    {
      key: 'roi',
      title: 'ROI',
      align: 'center',
      render: ({ stats: { roi } }: MonthlyUserStatistics) => (
        <DisplayDecimal
          value={roi}
          weight="medium"
          color={roi >= 0 ? colors.primary : colors.red}
          textAlign="center"
          showCurrency
        />
      ),
    },
    {
      key: 'passage',
      title: 'Passage',
      align: 'center',
      render: ({ stats: { won, lost } }: MonthlyUserStatistics) => (
        <DisplayDecimal value={won / (won + lost)} weight="medium" textAlign="center" />
      ),
    },
  ];

  return (
    <Container bordered innerSpace={20}>
      <Table showHeader pagination={false} columns={columns} data={monthlyStats} rowKey="date" />
    </Container>
  );
};
