import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { cognitoUserPool } from '../auth';
import { LoginRequest, VerifyUserRequest } from '../models';

export function useAuthApi() {
  const login = ({ username, password }: LoginRequest): Promise<CognitoUser> =>
    new Promise<CognitoUser>((resolve, reject) => {
      const user = new CognitoUser({
        Username: username,
        Pool: cognitoUserPool,
      });
      user.authenticateUser(new AuthenticationDetails({ Username: username, Password: password }), {
        onSuccess: () => {
          resolve(user);
        },
        onFailure: (err: Error) => {
          reject(err);
        },
      });
    });

  const resendCode = (username: string): Promise<null> => {
    return new Promise<null>((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: cognitoUserPool,
      });
      cognitoUser.resendConfirmationCode((error, _) => {
        if (error) {
          reject(error);
        }

        resolve(null);
      });
    });
  };

  const verify = ({ username, code }: VerifyUserRequest): Promise<null> => {
    return new Promise<null>((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: cognitoUserPool,
      });
      cognitoUser.confirmRegistration(code, false, error => {
        if (error) {
          reject(error);
        }

        resolve(null);
      });
    });
  };

  return {
    login,
    resendCode,
    verify,
  };
}
