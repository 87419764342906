import React from 'react';
import { FC, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { colors, fontWeights } from '../styles';
import styled from 'styled-components';

interface Props {
  to: string;
  color?: string;
  activeColor?: string;
}

export const NavTab: FC<PropsWithChildren<Props>> = ({
  children,
  to,
  color = colors.white,
  activeColor = colors.primary,
}) => (
  <StyledNavTab to={to} $color={color} $activeColor={activeColor}>
    {children}
  </StyledNavTab>
);

export const StyledNavTab = styled(NavLink)<{ $color?: string; $activeColor?: string }>`
  color: ${props => props.$color};
  font-weight: ${fontWeights.medium};

  .active {
    color: ${props => props.$activeColor};
  }
`;
