import React, { FC } from 'react';
import { Flex } from 'antd';
import { Container, DisplayText, Space } from './index';
import { colors } from '../styles';
import { UnorderedListOutlined } from '@ant-design/icons';
import { styled } from 'styled-components';

interface Props {
  width?: number;
  value: string | number;
  bordered?: boolean;
}

export const NoItems: FC<Props> = ({ value, width, bordered = false }) => (
  <>
    <Space size={20} />
    <Container width={width} bordered={bordered} innerSpace={20}>
      <Flex vertical align="center" gap="small">
        <StyledIcon />
        <DisplayText value={value} size="sectionTitle" weight="semiBold" color={colors.grey} uppercase />
      </Flex>
    </Container>
  </>
);

const StyledIcon = styled(UnorderedListOutlined)`
  font-size: 25px;
  color: ${colors.grey};
`;
