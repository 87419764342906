import React, { FC } from 'react';
import { HeadToHeadMatch, Team } from '../../../models';
import { ColumnsType } from 'antd/es/table';
import {
  CircledIcon,
  Container,
  DisplayDate,
  DisplayGoals,
  DisplayText,
  ResultBadge,
  Table,
} from '../../../components';
import { Avatar, Flex } from 'antd';

interface Props {
  matches: HeadToHeadMatch[];
  team: Team;
  color: string;
}

export const RivalLevelMatches: FC<Props> = ({ matches, team, color }) => {
  const columns: ColumnsType<HeadToHeadMatch> = [
    {
      key: 'result',
      render: (row: HeadToHeadMatch) => <ResultBadge match={row} team={team} />,
    },
    {
      key: 'date',
      dataIndex: 'scheduledAt',
      render: (scheduledAt: string) => <DisplayDate date={scheduledAt} twoLines />,
    },
    {
      key: 'teams',
      render: (row: HeadToHeadMatch) => (
        <Flex vertical gap={5} flex={1}>
          <Flex gap={5}>
            <Avatar size={23} src={row.home.logoUrl} />
            <DisplayText value={row.home.name} />
          </Flex>

          <Flex gap={5} flex={1}>
            <Avatar size={23} src={row.away.logoUrl} />
            <DisplayText value={row.away.name} />
          </Flex>
        </Flex>
      ),
      width: 200,
    },
    {
      key: 'goals',
      render: (row: HeadToHeadMatch) => (
        <Flex vertical gap={5} align="center">
          <DisplayGoals value={row.homeGoals} isHomeTeam />
          <DisplayGoals value={row.awayGoals} />
        </Flex>
      ),
    },
  ];

  return (
    <Container bordered innerSpace={15}>
      <Flex align="center" justify="center">
        <CircledIcon size={50} logo={team.logoUrl} color={color} />
      </Flex>
      <Table columns={columns} data={matches} pagination={false} showHeader={false} rowKey="id" cellPadding={6} />
    </Container>
  );
};
