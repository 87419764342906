import { Progress } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';

export interface Props {
  header: string;
  value: number;
  color: string;
  trailColor?: string;
  footer: string;
}

export const ProgressBar: FC<Props> = ({ header, value, color, footer }) => (
  <ProgressBarContainer>
    <p>{header}</p>
    <Progress type="line" strokeColor={color} percent={value} />
    <p>{footer}</p>
  </ProgressBarContainer>
);

const ProgressBarContainer = styled.div`
  text-align: center;
  justify-content: center;
`;
