import { useQuery } from '@tanstack/react-query';
import { useUsersApi } from '../api';
import { useMemo } from 'react';
import { useCognito } from '../auth';

export function useHeaderService() {
  const { getUser } = useUsersApi();
  const { currentUser } = useCognito();

  const id = useMemo(() => currentUser?.getUsername(), [currentUser]);

  const userResult = useQuery({
    queryKey: ['user', id],
    queryFn: () => (id ? getUser(id) : null),
  });

  return {
    user: userResult.data,
    loading: userResult.isFetching,
  };
}
