import React, { FC } from 'react';
import { Flex } from 'antd';
import { useProfileService } from '../ProfileService';
import { DisplayText } from 'src/components';
import { colors } from 'src/styles';
import { styled } from 'styled-components';

export const BetsBarChart: FC = () => {
  const { userStats } = useProfileService();
  const { won, pending, lost } = 
    userStats?.monthBetStats?.find(_ => true)?.stats ?? { won: 0, pending: 0, lost: 0 };

  const betsSum = won + pending + lost;

  return (
    <Flex vertical gap={3}>
      <Flex gap={5}>
        <Line $width={`${(won * 290) / betsSum}`} $color='primaryLight' />
        <DisplayText value={`${won} +`} size='normal' weight='medium' />
      </Flex>
      <Flex gap={5}>
        <Line $width={`${(pending * 290) / betsSum}`} $color='grey' />
        <DisplayText value={`${pending} =`} size='normal' weight='medium' />
      </Flex>
      <Flex gap={5}>
        <Line $width={`${(lost * 290) / betsSum}`} $color='redLight' />
        <DisplayText value={`${lost} -`} size='normal' weight='medium' />
      </Flex>
    </Flex>
  );
};

const Line = styled.div<{
  $width: string;
  $color: string;
}>`
  width: ${props => props.$width}px;
  height: 22px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: ${props => colors[props.$color]};
`;