export const colors: { [key: string]: string } = {
  background: '#ffffff',
  white: '#ffffff',
  primary: '#00be8c',
  primaryDark: '#2f2f2f',
  secondaryDark: '#545454',
  primaryLight: '#c6ece3',
  borderColor: '#c6c6c6',
  black: '#333333',
  red: '#ea274b',
  redLight: '#f5c7c7',
  grey: '#c8c8c8',
  greyLight: '#efefef',
  greyDark: '#bdbdbd',
  greyText: '#70726f',
  blue: '#b0d9f1',
};
