import React, { FC } from 'react';
import { Container, DisplayHeader } from '../../../components';
import { Flex } from 'antd';
import { AttackingPlayersTable, PassingPlayersTable, ValuablePlayersTable } from './components';
import { useMatchDetailsService } from '../MatchDetailsService';

function mapPlayersWithTeam<T>(players: T[], teamLogoUrl: string) {
  return players.map(player => ({
    ...player,
    team: teamLogoUrl,
  }));
}

export const PlayerStats: FC = () => {
  const { matchPlayers, matchDetails } = useMatchDetailsService();

  if (!matchPlayers || !matchDetails) return <></>;

  let homePlayers = mapPlayersWithTeam(matchPlayers.homeAttackingPlayers, matchDetails.match.home.logoUrl);
  let awayPlayers = mapPlayersWithTeam(matchPlayers.awayAttackingPlayers, matchDetails.match.away.logoUrl);
  let allPlayers = [...homePlayers, ...awayPlayers];

  let homePassingPlayers = mapPlayersWithTeam(matchPlayers.homePassingPlayers, matchDetails.match.home.logoUrl);
  let awayPassingPlayers = mapPlayersWithTeam(matchPlayers.awayPassingPlayers, matchDetails.match.away.logoUrl);
  let allPassingPlayers = [...homePassingPlayers, ...awayPassingPlayers];

  return (
    <Flex vertical gap={30}>
      <Flex vertical gap={20}>
        <DisplayHeader value="valuable players" />
        <Flex gap={20}>
          <ValuablePlayersTable
            data={matchPlayers.homeValuablePlayers}
            avatar={matchDetails.match.home.logoUrl}
            color="primaryLight"
          />
          <ValuablePlayersTable
            data={matchPlayers.awayValuablePlayers}
            avatar={matchDetails.match.away.logoUrl}
            color="blue"
          />
        </Flex>
      </Flex>

      <Flex vertical gap={20}>
        <DisplayHeader value="valuable attacking players" />
        <Container bordered innerSpace={15}>
          <Flex vertical gap={10}>
            <AttackingPlayersTable players={allPlayers} />
          </Flex>
        </Container>
      </Flex>

      <Flex vertical gap={20}>
        <DisplayHeader value="valuable passing players" />
        <Container bordered innerSpace={15}>
          <Flex vertical gap={10}>
            <PassingPlayersTable players={allPassingPlayers} />
          </Flex>
        </Container>
      </Flex>
    </Flex>
  );
};
