import React, { FC, PropsWithChildren } from 'react';
import { Layout, ConfigProvider, ThemeConfig } from 'antd';
import styled from 'styled-components';
import Header from './Header';
import { colors } from '../styles';

const LayoutContainer: FC<PropsWithChildren> = ({ children }) => {
  const themeConfig: ThemeConfig = {
    hashed: false,
    token: {
      colorPrimary: colors.primary,
      colorBorder: colors.greyDark,
      lineWidth: 2,
      colorTextPlaceholder: '#333333',
      colorTextQuaternary: '#333333',
      colorLink: colors.primary,
      fontFamily: 'Poppins',
      fontSizeIcon: 14,
    },
    components: {
      Layout: {
        headerBg: colors.primary,
        bodyBg: colors.background,
        colorBgLayout: colors.background,
      },
      Menu: {
        itemBg: colors.primaryDark,
        itemColor: colors.background,
        itemHoverColor: colors.background,
        groupTitleFontSize: 16,
        iconSize: 16,
      },
      Select: {
        fontSize: 14,
      },
      Table: {
        headerBg: colors.greyLight,
        fontFamily: 'Poppins',
        cellFontSize: 16,
      },
    },
  };

  return (
    <ConfigProvider theme={themeConfig}>
      <OuterLayout>
        <Header />
        <Layout>
          <Layout.Content>{children}</Layout.Content>
        </Layout>
      </OuterLayout>
    </ConfigProvider>
  );
};

const OuterLayout = styled(Layout)`
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 1700px;
`;

export default LayoutContainer;
