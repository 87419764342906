import React, { FC } from 'react';
import { DisplayDecimal, DisplayPercentage, DisplayText, UserAvatar } from '../../../components';
import { Flex } from 'antd';
import { colors } from '../../../styles';
import { styled } from 'styled-components';
import { useProfileService } from '../ProfileService';
import image from '../../../assets/img/profileImage.png';

export const UserInfo: FC = () => {
  const { userStats, id } = useProfileService();
  return (
    <>
      <ImageBackground />
      <User justify="space-between">
        <UserInfoContainer align="flex-end" gap={30}>
          <UserAvatar size={120} />
          <DisplayText value={`Hi, I'm ${id}!`} color={colors.white} size="sectionTitle" />
        </UserInfoContainer>
        <UserDetailsContainer align="center" justify="space-between" gap={50}>
          <Flex vertical>
            <DisplayText value="3" color={colors.primary} size="normal" textAlign="center" weight="medium" />
            <DisplayText value="Picks" color={colors.white} textAlign="center" />
          </Flex>

          <Flex vertical>
            <DisplayDecimal
              value={userStats?.annual.roi}
              color={colors.primary}
              size="normal"
              textAlign="center"
              weight="medium"
              showCurrency
            />
            <DisplayText value="Profit" color={colors.white} textAlign="center" />
          </Flex>

          <Flex vertical>
            <DisplayPercentage value={userStats?.annual.yield} color={colors.primary} size="normal" weight="medium" />
            <DisplayText value="Yield" color={colors.white} textAlign="center" />
          </Flex>
          <Flex vertical>
            <DisplayText value="0" color={colors.primary} size="normal" textAlign="center" weight="medium" />
            <DisplayText value="Followers" color={colors.white} textAlign="center" />
          </Flex>
        </UserDetailsContainer>
      </User>
    </>
  );
};

const ImageBackground = styled.div`
  width: 100%;
  height: 378px;
  background-color: rgba(0, 38, 28, 0.6);
  background-image: url(${image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const User = styled(Flex)`
  width: 100%;
  height: 56px;
  background-color: ${colors.primaryDark};
`;

const UserInfoContainer = styled(Flex)`
  margin-left: 100px;
  padding-bottom: 15px;
`;

const UserDetailsContainer = styled(Flex)`
  margin-right: 100px;
`;
