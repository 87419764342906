import React, { FC, useMemo } from 'react';
import { Button, Flex } from 'antd';
import { DisplayText, Space, useSignUpModalService } from '../../../components';
import { useCognito } from '../../../auth';
import { styled } from 'styled-components';
import mainImage from '../../../assets/img/mainImage.png';
import { colors } from 'src/styles';
import { DownOutlined } from '@ant-design/icons';
import { bounceAnimation } from 'src/utils';

interface WelcomeWindowProps {
  handleOnClick: () => void;
}

const WelcomeWindow: FC<WelcomeWindowProps> = ({ handleOnClick }) => {
  const { show } = useSignUpModalService();
  const { currentUser } = useCognito();
  const username = useMemo(() => currentUser?.getUsername(), [currentUser]);

  return (
    <Container vertical justify='flex-end'>
      <Flex justify='space-evenly' align='center'>
        <Flex vertical gap={10}>
          <DisplayText value="BetSmart" size="hero" weight="semiBold" color={colors.white} uppercase />
          <DisplayText value="Empowering Success, Unveiling Insights – Where Experts Share" size="title" color={colors.white} uppercase />
          <DisplayText value="Winning Strategies for Tomorrow's Victories." size="title" color={colors.white} uppercase />
        </Flex>
        {!username && (
          <Button type="primary" size="large" onClick={show}>
            <DisplayText value="join now" size="normal" weight="medium" color={colors.white} uppercase />
          </Button>
        )}
      </Flex>
      <Space size={200} />
      <Flex vertical justify='center' align='center' gap={10} onClick={handleOnClick}>
        <DisplayText value="show matches" size="sectionTitle" color={colors.white} uppercase />
        <StyledIcon />
      </Flex>      
    </Container>
  );
};

const Container = styled(Flex)`
  width: 100%;
  height: calc(100vh - 50px);
  background-image: url(${mainImage});
  background-size: 100vw 100vh;
  padding-bottom: 25px;
`;

const StyledIcon = styled(DownOutlined)`
  font-size: 24px;
  color: ${colors.white};
  animation: ${bounceAnimation} 1.5s ease infinite;
`;

export default WelcomeWindow;
