import { useQuery } from '@tanstack/react-query';
import { useMonitoringApi } from '../../api';

export function useMonitoringService() {
  const { getAggregatedResearchPrediction } = useMonitoringApi();

  const items = useQuery({
    queryKey: ['aggregated-prediction-items'],
    queryFn: () => getAggregatedResearchPrediction(),
  });

  return {
    items: items?.data ?? [],
    loading: items === null || items.isFetching,
  };
}
