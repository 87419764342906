import React, { FC } from 'react';
import { useMonitoringService } from './MonitoringService';
import { Container, Loading } from '../../components';
import { AggregationChart, EvaluatorColor, GroupKey } from './components';
import { Button, Flex } from 'antd';
import { groupBy } from '../../utils/groupBy';

const MonitoringScreen: FC = () => {
  const { items, loading } = useMonitoringService();
  const colors = ['#F18F01', '#048BA8', '#99C24D', '#ff0000', '#00ffff'];
  const [groupKey, setGroupKey] = React.useState<GroupKey>('successRate');
  const tokens = Object.keys(groupBy(items, 'token'));
  const evaluators = Object.keys(groupBy(items, 'evaluator'));
  const evaluatorColors: EvaluatorColor[] = evaluators.map((e, i) => ({ evaluator: e, color: colors[i]! }));

  return (
    <Loading loading={loading}>
      <Container innerSpace={20}>
        <Flex gap={20} justify="center">
          <Button type={groupKey === 'successRate' ? 'primary' : 'default'} onClick={() => setGroupKey('successRate')}>
            Success Rate
          </Button>
          <Button type={groupKey === 'frequency' ? 'primary' : 'default'} onClick={() => setGroupKey('frequency')}>
            Frequency
          </Button>
        </Flex>

        <Flex justify="space-around" wrap>
          {tokens.map(t => (
            <AggregationChart
              title={t}
              groupKey={groupKey}
              evaluatorColors={evaluatorColors}
              points={items?.filter(x => x.token === t)}
            />
          ))}
        </Flex>
      </Container>
    </Loading>
  );
};

export default MonitoringScreen;
