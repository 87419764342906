import React from 'react';
import { DisplayHeader, Space } from '../../../components';
import { Flex } from 'antd';
import { FullTournament } from './FullTournament';
import { useMatchDetailsService } from '../MatchDetailsService';
import { RivalLevelMatches } from './RivalLevelMatches';
import { StreaksList } from './StreaksList';

export const MatchStatistics = () => {
  const { matchDetails } = useMatchDetailsService();
  if (!matchDetails) return <></>;

  return (
    <Flex vertical>
      <Flex vertical>
        <DisplayHeader value="matches against rival level" />
        <Space size={20} />
        <Flex gap={20}>
          <RivalLevelMatches
            matches={matchDetails.homeTeamRivalLevelMatches}
            team={matchDetails.match.home}
            color="primaryLight"
          />
          <RivalLevelMatches
            matches={matchDetails.awayTeamRivalLevelMatches}
            team={matchDetails.match.away}
            color="blue"
          />
        </Flex>
      </Flex>

      <Space size={20} />

      <Flex vertical>
        <DisplayHeader value="team streaks" />
        <Space size={20} />
        <Flex gap={20}>
          <StreaksList
            streaks={matchDetails.homeTeamStreaks}
            logo={matchDetails.match.home.logoUrl}
            color="primaryLight"
          />
          <StreaksList streaks={matchDetails.awayTeamStreaks} logo={matchDetails.match.away.logoUrl} color="blue" />
        </Flex>
      </Flex>

      <Space size={20} />

      <Flex vertical>
        <DisplayHeader value="tournament" />
        <Space size={20} />
        <FullTournament matchDetails={matchDetails} />
      </Flex>
    </Flex>
  );
};
