import React, { FC } from 'react';
import { Button, Menu, Image, Flex } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserOutlined, TrophyOutlined, DesktopOutlined } from '@ant-design/icons';
import Logo from '../assets/img/Logo.svg';
import { Clickable, useLoginModalService, UserAvatar, useSignUpModalService, DisplayText } from '../components';
import { useHeaderService } from './HeaderService';
import { styled } from 'styled-components';
import { colors } from 'src/styles';

const items = [
  {
    label: <Link to={'/matches'}>MATCHES</Link>,
    key: 'matches',
    icon: <TrophyOutlined />,
  },
  {
    label: <Link to={'/bets'}>BETS</Link>,
    key: 'bets',
    icon: <UserOutlined />,
  },
  {
    label: <Link to={'/monitoring'}>MONITORING</Link>,
    key: 'monitoring',
    icon: <DesktopOutlined />,
  },
];

const getSelectedKey = (path: string) => {
  return path === '/matches' ? 'matches' : path === '/bets' ? 'bets' : '';
};

const Header: FC = () => {
  const { show: showLogin } = useLoginModalService();
  const { show: showSignup } = useSignUpModalService();
  const { user } = useHeaderService();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedKey, setSelectedKey] = React.useState(getSelectedKey(location.pathname));

  React.useEffect(() => {
    setSelectedKey(getSelectedKey(location.pathname));
  }, [location.pathname]);

  const redirectToProfile = (id: string) => {
    navigate(`/users/${id}`);
  };

  return (
    <Background justify='space-between' align='center'>
      <Flex gap={25} align='center'>
        <Link to="/">
          <Image src={Logo} alt="Logo" preview={false} />
        </Link>
        <StyledMenu selectedKeys={[selectedKey]} mode="horizontal" items={items} />
      </Flex>
      
      <Flex justify='center' align='center'>
        {user ? (
          <Clickable onClick={() => redirectToProfile(user.id)}>
            <Flex align='center' gap={10}>
              <UserAvatar size={30} />
              <DisplayText value={`${user.balance}$`} color={colors.white} />
              <DisplayText value={user.id} color={colors.white} />
            </Flex>
          </Clickable>
        ) : (
            <Flex gap={15}>
              <SignInButton onClick={showLogin} type="primary">
                <DisplayText value="sign in" color={colors.primary} uppercase />
              </SignInButton>
              <SignUpButton onClick={showSignup} type="primary">
                <DisplayText value="sign up" color={colors.white} uppercase />
              </SignUpButton>
            </Flex>
        )}
      </Flex>
    </Background>
  );
};

const Background = styled(Flex)`
  width: 100%;
  height: 56px;
  position: sticky;
  z-index: 1;
  top: 0px;
  background-color: ${colors.primaryDark};
  padding: 0 25px;
`;

const StyledMenu = styled(Menu)`
  width: 500px;

  .ant-menu-item {
    border-right: 1px solid ${colors.primary};
  }  
`;

const SignInButton = styled(Button)`
  width: 110px;
  background-color: ${colors.primaryDark};
  border: 2px solid ${colors.primary};
`;

const SignUpButton = styled(Button)`
  width: 110px;
  background-color: ${colors.primary};
  border: none;
`;

export default Header;
