import React, { FC, useMemo } from 'react';
import { Avatar, Button, Flex } from 'antd';
import {
  Clickable,
  Container,
  DisplayDate,
  DisplayPercentage,
  DisplayText,
  PredictionLabel,
  useCreateBetModalService,
  UserAvatar,
} from '.';
import { Bet, BetStatus } from '../models';
import { useNavigate } from 'react-router-dom';
import { useCognito } from '../auth';
import { styled } from 'styled-components';
import { colors } from '../styles';
import dayjs from 'dayjs';
import { RetweetOutlined, LikeFilled, DislikeFilled } from '@ant-design/icons';

interface Props {
  bet: Bet;
}

export const BetCard: FC<Props> = ({ bet }) => {
  const roi = bet.user.yieldScore;
  const { show } = useCreateBetModalService();
  const onCreateBet = (bet: Bet) => {
    show(bet.token, bet.match.id);
  };

  const navigate = useNavigate();
  const redirectToProfile = (id: string) => {
    navigate(`/users/${id}`);
  };

  const redirectToMatch = (id: string) => {
    navigate(`/matches/${id}`);
  };

  const { currentUser } = useCognito();
  const username = useMemo(() => currentUser?.getUsername(), [currentUser]);

  const notStarted = dayjs(bet.match.scheduledAt) > dayjs();
  const inProgress =
    dayjs(bet.match.scheduledAt) <= dayjs() && bet.match.homeGoals === null && bet.match.awayGoals === null;
  const finished = bet.match.homeGoals !== null && bet.match.awayGoals !== null;

  return (
    <Container bordered innerSpace={15}>
      <Flex vertical gap="middle">
        <Flex justify="space-between">
          <Flex align="center" gap="small">
            <Avatar size={35} src={bet.match.countryFlag} />
            <DisplayText value={bet.match.league} size="normal" weight="semiBold" uppercase={true} />
          </Flex>

          <Clickable onClick={() => redirectToMatch(bet.match.id)}>
            <Flex justify="flex-end" align="center" gap="small">
              <Avatar size={35} src={bet.match.homeTeamLogo} />
              <DisplayText
                value={`${bet.match.homeTeam} - ${bet.match?.awayTeam}`}
                size="normal"
                weight="semiBold"
                uppercase={true}
              />
              <Avatar size={35} src={bet.match.awayTeamLogo} />
            </Flex>
          </Clickable>
        </Flex>

        <Flex justify="space-between" align="center">
          <DisplayDate size="normal" weight="medium" date={bet.match.scheduledAt} showDateOnly showFullDate />
          {notStarted && (
            <Flex justify="end" gap={5}>
              <DisplayText value="Will Start At" size="normal" weight="medium" />
              <DisplayDate size="normal" weight="medium" date={bet.match.scheduledAt} showTimeOnly />
            </Flex>
          )}
          {finished && (
            <DisplayText
              value={`Match Score: ${bet.match?.homeGoals} - ${bet.match?.awayGoals}`}
              size="normal"
              weight="medium"
            />
          )}
          {inProgress && <DisplayText value="The Match Is Underway" size="normal" weight="medium" />}
        </Flex>

        <Clickable onClick={() => redirectToProfile(bet.user.id)}>
          <BorderedSpace>
            <Flex justify="space-between">
              <Flex gap={10} align="center">
                <UserAvatar size={35} />
                <DisplayText value={bet.user.id} size="normal" weight="medium" />
              </Flex>

              <Flex align="center">
                <DisplayText value={`${bet.user.betsWon}+`} color={colors.primary} size="normal" weight="medium" />
                <DisplayText value={`${bet.user.betsPending}=`} color={colors.grey} size="normal" weight="medium" />
                <DisplayText value={`${bet.user.betsLost}-`} color={colors.red} size="normal" weight="medium" />
              </Flex>
              <Flex gap={5} align="center">
                <DisplayText value="YIELD" size="normal" weight="medium" />
                <DisplayPercentage
                  value={roi}
                  color={roi < 0 ? colors.red : colors.primary}
                  size="normal"
                  weight="medium"
                />
              </Flex>
              <Flex vertical align="center">
                <DisplayText value="10 %" size="normal" weight="medium" />
                <DisplayText value="Of The Bank" size="normal" weight="medium" />
              </Flex>
            </Flex>
          </BorderedSpace>
        </Clickable>

        <Comment>
          <DisplayText value={bet.description} size="normal" weight="medium" />
        </Comment>
        <Flex justify="space-between">
          <Flex gap="small" align="center">
            <LikeIcon />
            <DisplayText value="0" size="normal" weight="semiBold" color={colors.grey} />
            <DislikeIcon />
            <DisplayText value="0" size="normal" weight="semiBold" color={colors.grey} />
          </Flex>
          <PredictionLabel token={bet.token} value={bet.predictionValue} odd={bet.odd} />
          <ButtonBet
            type="primary"
            size="large"
            disabled={bet.status !== BetStatus.Pending || username === bet.user.id}
            onClick={() => onCreateBet(bet)}>
            <Flex justify="space-between" gap={10}>
              <RetweetIcon />
              <DisplayText
                value="Bet Now"
                size="normal"
                weight="medium"
                color="white"
                textAlign="center"
                uppercase={true}
              />
            </Flex>
          </ButtonBet>
        </Flex>
      </Flex>
    </Container>
  );
};

const ButtonBet = styled(Button)`
  width: 150px;
  background-color: ${props => (props.disabled ? colors.grey : colors.primary)}!important;
  border-radius: 13px;
  && {
    color: ${props => (props.disabled ? colors.black : colors.white)}!important;
    border: ${props => (props.disabled ? 'none' : 'initial')};
  }
`;

const BorderedSpace = styled.div`
  padding: 5px 20px;
  border: 2px solid ${colors.grey};
  border-radius: 13px;
`;

const Comment = styled.div`
  background-color: ${colors.greyLight};
  border-radius: 13px;
  padding: 10px 20px;
`;

const LikeIcon = styled(LikeFilled)`
  font-size: 20px;
  color: ${colors.grey};
`;

const DislikeIcon = styled(DislikeFilled)`
  font-size: 20px;
  color: ${colors.grey};
  margin-top: 7px;
`;

const RetweetIcon = styled(RetweetOutlined)`
  font-size: 20px;
  color: ${colors.white};
`;
