import { Avatar, Flex } from 'antd';
import { CircledIcon, Container, DisplayPercentage, DisplayText, Table } from '../../../../components';
import { styled } from 'styled-components';
import { ColumnsType } from 'antd/es/table';
import React, { FC } from 'react';
import { DisplayInjury } from './index';
import { Injury, ValuablePlayer } from '../../../../models';

const columns: ColumnsType<ValuablePlayer> = [
  {
    key: 'player',
    title: 'Player',
    align: 'center',
    width: 200,
    render: (row: ValuablePlayer) => (
      <Flex gap={5} align="center">
        <Avatar size={30} src={row.avatar} />
        <DisplayText value={row.name} />
      </Flex>
    ),
  },
  {
    key: 'rating',
    title: 'Rating',
    dataIndex: 'rating',
    align: 'center',
    width: 80,
    render: (rating: string) => <DisplayText value={rating} textAlign="center" />,
  },
  {
    key: 'minutes',
    title: 'Minutes',
    dataIndex: 'minutes',
    align: 'center',
    width: 80,
    render: (minutes: number) => <DisplayPercentage value={minutes} textAlign="center" />,
  },
  {
    key: 'averagePoints',
    title: 'Points',
    align: 'center',
    width: 100,
    render: (row: ValuablePlayer) => {
      const value = `${row.averagePointsWithPlayer}/${row.averageTeamPoints}/${row.averagePointsWithoutPlayer}`;
      return <DisplayText value={value} textAlign="center" />;
    },
  },
  {
    key: 'injury',
    dataIndex: 'injury',
    title: 'Availability',
    width: 90,
    render: (injury?: Injury) => <DisplayInjury injury={injury} />,
  },
];

interface Props {
  data: ValuablePlayer[];
  avatar: string;
  color: string;
}

export const ValuablePlayersTable: FC<Props> = ({ data, avatar, color }) => {
  return (
    <Container width={500} bordered innerSpace={10}>
      <Flex vertical gap={20}>
        <TeamHeader>
          <Flex justify="center" align="center">
            <CircledIcon size={50} logo={avatar} color={color} />
          </Flex>
        </TeamHeader>
        <Table showHeader pagination={false} columns={columns} data={data} rowKey={'player'} cellPadding={3} />
      </Flex>
    </Container>
  );
};

const TeamHeader = styled.div`
  width: 100%;
  height: 45px;
  border-radius: 10px;
  padding-top: 7px;
`;
