import React, { FC } from 'react';
import { Image } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Match } from '../models';

interface Props {
  match: Match;
}

export const Fixture: FC<Props> = ({ match }) => (
  <div key={match.id}>
    <HomeTeamLogo preview={false} height={20} width={20} src={match.home.logoUrl} alt="homeTeam" />
    <TeamName>{match.home.name}</TeamName>
    <Score to={`/matches/${match.id}`}>
      {match?.homeGoals ?? '-'}:{match?.awayGoals ?? '-'}
    </Score>
    <TeamName>{match.away.name}</TeamName>
    <AwayTeamLogo preview={false} height={20} width={20} src={match.away.logoUrl} alt="awayTeam" />
  </div>
);

const HomeTeamLogo = styled(Image)`
  padding-right: 0.25rem;
`;

const AwayTeamLogo = styled(Image)`
  padding-left: 0.25rem;
`;

const TeamName = styled.span`
  font-size: 15px;
`;

const Score = styled(Link)`
  font-size: 15px;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
`;
