import dayjs from 'dayjs';
import React, { FC } from 'react';
import { DisplayText } from './DisplayText';
import { Flex } from 'antd';
import { FontSize, FontWeight } from '../styles';

interface Props {
  date?: string;
  showDateOnly?: boolean;
  showTimeOnly?: boolean;
  showFullDate?: boolean;
  customFormat?: string;
  size?: FontSize;
  weight?: FontWeight;
  color?: string;
  twoLines?: boolean;
  textAlign?: 'start' | 'center' | 'end';
}

export const DisplayDate: FC<Props> = ({
  date,
  showDateOnly = false,
  showTimeOnly = false,
  showFullDate = false,
  customFormat = undefined,
  weight = 'normal',
  size = 'small',
  color,
  twoLines = false,
  textAlign = 'start',
}) => {
  if (customFormat) {
    return (
      <DisplayText
        value={dayjs(date).format(customFormat)}
        weight={weight}
        size={size}
        color={color}
        textAlign={textAlign}
      />
    );
  }

  const day = dayjs(date).format(showFullDate ? 'DD MMMM YYYY' : 'DD MMM YY');
  const time = dayjs(date).format('HH:mm');
  return (
    <Flex vertical={twoLines} align="center" justify="center">
      {!(!showDateOnly && showTimeOnly) && <DisplayText value={day} weight={weight} size={size} color={color} />}
      {!(showDateOnly && !showTimeOnly) && <DisplayText value={time} weight={weight} size={size} color={color} />}
    </Flex>
  );
};
