import React, { FC } from 'react';
import { Container, DisplayDecimal, DisplayPercentage, DisplayText, Space } from '../../../components';
import { Avatar, Flex } from 'antd';
import yieldImg from '../../../assets/img/yield.png';
import roiImg from '../../../assets/img/roi.png';
import { UserStats } from 'src/models';
import { colors } from 'src/styles';

interface Props {
  userStats: UserStats;
}

export const ProfitStatistics: FC<Props> = ({ userStats }) => {
  const stat =
    userStats.monthBetStats.length > 0
      ? userStats.monthBetStats[0]!.stats
      : { yield: 0, roi: 0, maxStreak: 0, maxProfit: 0 };

  return (
    <Flex>
      <Container width={410} height={210} bordered innerSpace={20}>
        <Space size={5} />

        <Flex justify="space-around" align="center">
          <Flex vertical align="center">
            <Avatar size={70} shape="square" src={yieldImg} />
            <Space size={5} />
            <Flex gap={10} align="center">
              <DisplayText weight="medium" size="sectionTitle" value="YIELD:" />
              <DisplayPercentage value={stat.yield} color={colors.primary} size="sectionTitle" weight="medium" />
            </Flex>
          </Flex>

          <Flex vertical align="center">
            <Avatar size={70} shape="square" src={roiImg} />
            <Space size={5} />
            <Flex gap={10} align="center">
              <DisplayText weight="medium" size="sectionTitle" value="ROI:" />
              <DisplayDecimal
                value={stat.roi}
                color={colors.primary}
                size="sectionTitle"
                weight="medium"
                showCurrency
              />
            </Flex>
          </Flex>
        </Flex>

        <Space size={25} />

        <Flex justify="space-between">
          <DisplayText weight="medium" size="normal" value="Maximum Streak" />
          <DisplayText weight="medium" size="normal" value={stat.maxStreak} />
        </Flex>

        <Flex justify="space-between">
          <DisplayText weight="medium" size="normal" value="Maximum Winnings" />
          <DisplayDecimal weight="medium" size="normal" value={stat.maxProfit} showCurrency />
        </Flex>
      </Container>
    </Flex>
  );
};
