import React, { FC, RefObject } from 'react';
import { DatePicker, Select, Avatar, Button, Flex } from 'antd';
import { CheckCircleOutlined, CalendarOutlined, PieChartOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useMatchesListService } from '../MatchesListService';
import { DisplayHeader, Space } from '../../../components';
import { colors, fontSizes, fontWeights } from '../../../styles';
import { styled } from 'styled-components';

export interface MatchesGridFiltersProps {
  ref: RefObject<HTMLElement>;
}

const MatchesGridFilters: FC<MatchesGridFiltersProps> = ({ ref }) => {
  const { leagues, config, setConfig } = useMatchesListService();
  const onSelectLeagues = (value: string[]) => setConfig({ ...config, leagueIds: value });
  const onChangeDate = (dateString: string | string[]) => setConfig({ ...config, date: dateString as string });

  if (leagues.length === 0) {
    return <></>;
  }

  return (
    <Flex ref={ref} vertical={true} justify="center">
      <DisplayHeader value="matches" />

      <Space size={10} />

      <Flex justify="space-between">
        <StyledButton
          type={config.status === 'all' ? 'primary' : 'default'}
          icon={<PieChartOutlined />}
          onClick={() => setConfig({ ...config, status: 'all' })}>
          All
        </StyledButton>

        <StyledButton
          type={config.status === 'upcoming' ? 'primary' : 'default'}
          icon={<CalendarOutlined />}
          onClick={() => setConfig({ ...config, status: 'upcoming' })}>
          Upcoming
        </StyledButton>

        <StyledButton
          type={config.status === 'finished' ? 'primary' : 'default'}
          icon={<CheckCircleOutlined />}
          onClick={() => setConfig({ ...config, status: 'finished' })}>
          Finished
        </StyledButton>

        <StyledSelect
          mode={'multiple'}
          placeholder="Please select league"
          value={config.leagueIds}
          onChange={(value: string[], _: any) => onSelectLeagues(value as string[])}
          optionLabelProp="label">
          {leagues.map(l => (
            <Select.Option key={l.id} value={l.id} label={l.name}>
              <Flex align="center">
                <Avatar size={30} src={l.countryFlag} />
                <span>{l.name}</span>
              </Flex>
            </Select.Option>
          ))}
        </StyledSelect>

        <StyledDatepicker
          allowClear={false}
          onChange={(_: any, dateString: string | string[]) => onChangeDate(dateString)}
          defaultValue={dayjs(config.date)}
          format={'DD MMM YYYY'}
        />
      </Flex>
    </Flex>
  );
};

const StyledButton = styled(Button)`
  height: 35px;
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.normal};
  text-transform: uppercase;
`;

const StyledSelect = styled(Select)`
  height: 35px;
  width: 290px;
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.normal};
  text-transform: uppercase;

  input {
    font-size: ${colors.fontSizeSmall};
    font-weight: ${colors.fontWeightNormal};
    text-transform: uppercase;
  }
`;

const StyledDatepicker = styled(DatePicker)`
  height: 35px;
  width: 290px;
  font-size: ${colors.fontSizeSmall};
  font-weight: ${colors.fontWeightNormal};
  text-transform: uppercase;

  input {
    font-size: ${colors.fontSizeSmall};
    font-weight: ${colors.fontWeightNormal};
    text-transform: uppercase;
  }
`;

export default MatchesGridFilters;
