import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Avatar, Flex } from 'antd';
import { Match, Prediction } from '../../../models';
import GridPrediction from './GridPrediction';
import { ColumnsType } from 'antd/es/table';
import {
  Container,
  useCreateBetModalService,
  Space,
  DisplayText,
  DisplayDate,
  DisplayGoals,
  Table,
  Clickable,
  NoItems,
} from '../../../components';
import { useMatchesListService } from '../MatchesListService';
import { styled } from 'styled-components';
import { colors } from '../../../styles';
import { RightOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { MatchStatus } from 'src/models/Match/MatchStatus';

const MatchesGrid: FC = () => {
  const { show } = useCreateBetModalService();
  const { updateFavouriteLeague, leagueMatches } = useMatchesListService();

  const navigate = useNavigate();

  const redirectToMatchDetails = (match: Match) => {
    navigate(`/matches/${match.id}`);
    window.scrollTo(0, 0);
  };

  const onCreateBet = (prediction: Prediction, match: Match) => {
    show(prediction.token, match.id);
  };

  const columns: ColumnsType<Match> = [
    {
      key: 'date',
      width: 80,
      render: (row: Match) => (
        <Clickable onClick={() => redirectToMatchDetails(row)}>
          {row.status === MatchStatus.Postponed || row.status === MatchStatus.Cancelled 
            ? <DisplayText value={row.status} />
            : <DisplayDate date={row.scheduledAt} showTimeOnly />
          }
          
        </Clickable>
      ),
    },
    {
      key: 'teams',
      render: (row: Match) => (
        <Clickable onClick={() => redirectToMatchDetails(row)}>
          <Row>
            <Col span={20}>
              <Flex gap={5}>
                <Avatar size={23} src={row.home.logoUrl} />
                <DisplayText value={row.home.name} overflow />
              </Flex>
            </Col>
            <Col span={4}>
              <DisplayGoals value={row.homeGoals} isHomeTeam />
            </Col>
          </Row>

          <Row>
            <Col span={20}>
              <Flex gap={5}>
                <Avatar size={23} src={row.away.logoUrl} />
                <DisplayText value={row.away.name} overflow />
              </Flex>
            </Col>
            <Col span={4}>
              <DisplayGoals value={row.awayGoals} />
            </Col>
          </Row>
        </Clickable>
      ),
    },
    {
      key: 'predictionOne',
      width: 150,
      render: (row: Match) => {
        return (
          row.predictions.length > 0 && (
            <GridPrediction prediction={row.predictions[0]!} onClick={() => onCreateBet(row.predictions[0]!, row)} />
          )
        );
      },
    },
    {
      key: 'predictionTwo',
      width: 150,
      render: (row: Match) => {
        return (
          row.predictions.length > 1 && (
            <GridPrediction prediction={row.predictions[1]!} onClick={() => onCreateBet(row.predictions[1]!, row)} />
          )
        );
      },
    },
    {
      key: 'predictionThree',
      width: 150,
      render: (row: Match) => {
        return (
          row.predictions.length > 2 && (
            <GridPrediction prediction={row.predictions[2]!} onClick={() => onCreateBet(row.predictions[2]!, row)} />
          )
        );
      },
    },
    {
      key: 'details',
      width: 150,
      render: (row: Match) => (
        <Clickable onClick={() => redirectToMatchDetails(row)}>
          <Flex justify="space-around" align="center">
            <DisplayText value="Details" />
            <RightOutlined color={colors.primary} />
          </Flex>
        </Clickable>
      ),
    },
  ];

  if (!leagueMatches.length) {
    return <NoItems value="No Matches" bordered />;
  }

  return (
    <Container bordered innerSpace={15}>
      <Flex vertical justify="center" gap={15}>
        {leagueMatches.map(({ name, countryFlag, id, favourite, matches }) => (
          <Flex vertical>
            <LeagueBar justify="space-between" align="center">
              <Flex align="center" gap={10}>
                <Avatar size={40} src={countryFlag} />
                <DisplayText value={name} size="normal" weight="medium" />
              </Flex>

              <Flex align="center">
                <StyledIcon as={favourite ? StarFilled : StarOutlined} onClick={() => updateFavouriteLeague(id, !favourite)} />
              </Flex>
            </LeagueBar>

            <Table
              data={matches}
              columns={columns}
              pagination={false}
              showHeader={false}
              rowKey="id"
              noDataText="No Matches"
              cellPadding={6}
              showVerticalBorders
            />
          </Flex>
        ))}
      </Flex>

      <Space size={30} />
    </Container>
  );
};

const LeagueBar = styled(Flex)`
  height: 60px;
  background-color: ${colors.greyLight};
  border-radius: 11px;
  padding: 10px 45px;
  margin-bottom: 15px;
`;

const StyledIcon = styled.div`
  font-size: 20px;
  color: ${colors.primary};
`;

export default MatchesGrid;
