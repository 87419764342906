import React, { FC } from 'react';
import { styled } from 'styled-components';

interface Props {
  size: number;
}

export const Space: FC<Props> = ({ size }) => <StyledDiv $size={size} />;

export const StyledDiv = styled.div<{ $size: number }>`
  margin-bottom: ${props => props.$size}px;
`;
