import React, { FC } from 'react';
import { Button, Flex } from 'antd';
import { Prediction, PredictionTokens } from '../../../models';
import { DisplayText, useCreateBetModalService } from '../../../components';
import { styled } from 'styled-components';
import { colors } from '../../../styles';

export interface Props {
  matchId: string;
  prediction?: Prediction;
  wide?: boolean;
}

export const BetButton: FC<Props> = ({ matchId, prediction, wide = false }) => {
  const { show } = useCreateBetModalService();
  if (!prediction) return <></>;

  const { value, token, odd } = prediction;
  const header = PredictionTokens[token];
  const leftValue = value ? `${value}%` : '';
  const rightValue = odd ?? '';
  const text = leftValue && rightValue ? `${leftValue} / ${rightValue}` : `${leftValue}${rightValue}`;

  if (wide) {
    return (
      <Bet disabled={!odd} onClick={() => show(token, matchId)}>
        <ButtonContainer justify="space-between">
          <DisplayText value={header} />
          <ValueContainer $value={prediction?.value}>
            <DisplayText value={text} textAlign="center" size="small" />
          </ValueContainer>
        </ButtonContainer>
      </Bet>
    );
  }

  return (
    <Bet disabled={!odd} onClick={() => show(token, matchId)}>
      <Flex align="center" justify="center" vertical>
        <DisplayText value={header} />
        <ValueContainer $value={prediction?.value}>
          <DisplayText value={text} textAlign="center" size="small" />
        </ValueContainer>
      </Flex>
    </Bet>
  );
};

const Bet = styled(Button)`
  width: 100%;
  min-height: 50px;
  height: 100%;
  border: 1px solid ${colors.greyDark};
  border-radius: 11px;
  padding: 3px 10px;
`;

const ValueContainer = styled.div<{ $value?: number }>`
  padding: 1px 3px;
  border-radius: 5px;
  background-color: ${props => {
    if (!props.$value) return colors.greyLight;
    if (props.$value < 75) return colors.redLight;
    if (props.$value < 85) return colors.grey;
    return colors.primaryLight;
  }};

  ${props => 
    !!props.$value && 
    `border: 1px solid ${colors.grey}`
  }
`;

const ButtonContainer = styled(Flex)`
  width: 100%;
`;
