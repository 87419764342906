import React, { FC } from 'react';
import { Button, Col, Flex, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Recommendation } from '../../../models';
import { useNavigate } from 'react-router-dom';
import { Clickable, DisplayText, Container, Space, CircledIcon } from '../../../components';
import { colors } from '../../../styles';
import { styled } from 'styled-components';

interface Props {
  recommendation: Recommendation;
  onCreateBet: (recommendation: Recommendation) => void;
}

const RecommendationCard: FC<Props> = ({ recommendation, onCreateBet }) => {
  const navigate = useNavigate();
  const redirectToMatchDetails = () => navigate(prediction.match!.id);

  const { prediction } = recommendation;

  return (
    <Container bordered width={260} innerSpace={15}>
      <Flex vertical gap={5}>
        <Flex justify="space-between">
          <Flex vertical align="center">
            <DisplayText size="normal" value={dayjs(prediction.match!.scheduledAt).format('HH:mm')} />
            <DisplayText value={dayjs(prediction.match!.scheduledAt).format('DD MMM')} />
          </Flex>
          <Flex vertical align="center">
            <DisplayText color={colors.red} size="normal" value="+25" />
            <DisplayText color={colors.red} value="forecasts" />
          </Flex>
        </Flex>

        <Clickable onClick={redirectToMatchDetails}>
          <Flex justify="center" align="center" vertical>
            <CircledIcon logo={prediction.match!.countryFlag} size={45} color="grey" />
            <DisplayText value={prediction.match!.league} />
          </Flex>

          <Space size={10} />

          <Container height={130}>
            <Row>
              <Col span={10}>
                <CircledIcon logo={prediction.match!.homeTeamLogo} size={60} color="primaryLight" />
              </Col>
              <Col span={4} flex="stretch">
                <FullHeightFlex justify="center" align="center">
                  <VersusIcon />
                </FullHeightFlex>
              </Col>
              <Col span={10}>
                <CircledIcon logo={prediction.match.awayTeamLogo} size={60} color="blue" />
              </Col>
            </Row>

            <Row>
              <Col span={10}>
                <DisplayText value={prediction.match!.homeTeam} textAlign="center" />
              </Col>
              <Col span={10} offset={4}>
                <DisplayText value={prediction.match!.awayTeam} textAlign="center" />
              </Col>
            </Row>
          </Container>
        </Clickable>

        <Clickable onClick={() => onCreateBet(recommendation)}>
          <BetButton>
            <Flex vertical justify="center" gap={3}>
              <Flex justify="center" gap={5}>
                <DisplayText size="normal" value={prediction.token} />
                <DisplayText size="normal" color={colors.primary} value={`${prediction.value}%`} weight="semiBold" />
              </Flex>
              <Flex justify="center">
                <OddValueContainer>
                  <DisplayText size="normal" value={prediction!.odd!} weight="semiBold" />
                </OddValueContainer>
              </Flex>
            </Flex>
          </BetButton>
        </Clickable>
      </Flex>
    </Container>
  );
};

const VersusIcon = styled(CloseOutlined)`
  display: block;
  width: 20px;
  height: 20px;
`;

const FullHeightFlex = styled(Flex)`
  height: 100%;
`;

const OddValueContainer = styled.div`
  background-color: ${colors.greyLight};
  border-radius: 4px;
  padding: 2px 5px;
`;

const BetButton = styled(Button)`
  width: 100%;
  height: 100%;
  border: 2px solid ${colors.greyDark};
  border-radius: 11px;
  padding: 7px 10px;
`;

export default RecommendationCard;
