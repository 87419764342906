import React, { FC } from 'react';
import { Button, Col, Flex, Row } from 'antd';
import { MatchDetails } from '../../../models';
import { BetGroupContainer } from './BetGroupContainer';
import { BetButton } from './BetButton';
import { BetAvatarButton } from './BetAvatarButton';
import draw from '../../../assets/icons/draw.svg';
import { Container, DisplayHeader, DisplayText, Space } from '../../../components';
import { styled } from 'styled-components';
import { colors } from '../../../styles';

interface BetsProps {
  matchDetails?: MatchDetails;
}

const Bets: FC<BetsProps> = ({ matchDetails }) => {
  const predictions = matchDetails?.match.predictions ?? [];
  if (!predictions.length) return <></>;

  const matchId = matchDetails!.match.id;

  return (
    <>
      <DisplayHeader value='place a bet' />
      <Space size={20} />
      <Container innerSpace={15} bordered>
        <Flex vertical gap={20}>
          <Flex justify="space-between">
            <BetAvatarButton
              matchId={matchId}
              title={matchDetails!.match.home.name}
              src={matchDetails!.match.home.logoUrl}
              prediction={predictions.find(x => x.token === 'Home')}
            />

            <BetAvatarButton
              matchId={matchId}
              title="Draw"
              src={draw}
              prediction={predictions.find(x => x.token === 'Draw')}
            />

            <BetAvatarButton
              matchId={matchId}
              title={matchDetails!.match.away.name}
              src={matchDetails!.match.away.logoUrl}
              prediction={predictions.find(x => x.token === 'Away')}
            />
          </Flex>

          <BetGroupContainer header="1X2 (Ordinary Time)" collapsed>
            <Flex vertical gap={6}>
              <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'HomeDraw')} wide />
              <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'DrawAway')} wide />
              <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'HomeAway')} wide />
            </Flex>
          </BetGroupContainer>

          <BetGroupContainer header="Goal Scored" collapsed>
            <Flex vertical gap={6}>
              <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'HomeScored')} wide />
              <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'AwayScored')} wide />
              <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'BothScored')} wide />
            </Flex>
          </BetGroupContainer>

          <BetGroupContainer header="Total Over/Under" collapsed>
            <Row gutter={[6, 6]}>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'TotalOver15')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'TotalUnder15')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'TotalOver25')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'TotalUnder25')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'TotalOver35')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'TotalUnder35')} />
              </Col>
            </Row>
          </BetGroupContainer>

          <BetGroupContainer header={`${matchDetails?.match.home.name} Over/Under`} collapsed>
            <Row gutter={[6, 6]}>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'HomeOver15')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'HomeUnder15')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'HomeOver25')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'HomeUnder25')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'HomeOver35')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'HomeUnder35')} />
              </Col>
            </Row>
          </BetGroupContainer>

          <BetGroupContainer header={`${matchDetails?.match.away.name} Over/Under`} collapsed>
            <Row gutter={[6, 6]}>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'AwayOver15')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'AwayUnder15')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'AwayOver25')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'AwayUnder25')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'AwayOver35')} />
              </Col>
              <Col span={12}>
                <BetButton matchId={matchId} prediction={predictions.find(x => x.token === 'AwayUnder35')} />
              </Col>
            </Row>
          </BetGroupContainer>
        </Flex>

        <Space size={15} />

        <StyledButton>
          <Flex justify="center" align="center">
            <DisplayText value="Show More" color={colors.black} weight="medium" uppercase />
          </Flex>
        </StyledButton>
      </Container>
    </>
  );
};

export default Bets;

const StyledButton = styled(Button)`
  width: 100%;
  height: 45px;
`;
