import React from 'react';
import { Recommendation } from '../models';
import { useApi } from './api';
import dayjs from 'dayjs';

export function useRecommendationsApi() {
  const { api } = useApi();

  const getRecommendations = React.useCallback(
    async (from: string, to: string) => {
      var params = {
        from: dayjs(from).startOf('day').toISOString(),
        to: dayjs(to).startOf('day').toISOString(),
      };

      return await api<null, Recommendation[]>('GET', 'recommendations', params);
    },
    [api],
  );

  return {
    getRecommendations,
  };
}
