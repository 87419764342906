import React, { FC } from 'react';
import { useBetsListService } from './BetsListService';
import { Avatar, Button, Flex, Select } from 'antd';
import { BetFilterStatuses } from '../../models';
import { PieChartOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { DisplayText } from '../../components';
import { colors, fontWeights } from '../../styles';

export const BetsFilters: FC = () => {
  const { config, setConfig, leagues } = useBetsListService();

  return (
    <Flex justify="space-evenly">
      <Button
        type={(config.status & BetFilterStatuses.Won) === BetFilterStatuses.Won ? 'primary' : 'default'}
        size="large"
        onClick={() => setConfig({ ...config, status: config.status ^ BetFilterStatuses.Won })}>
        <Flex gap={5}>
          <PieChartOutlined style={{ fontSize: '14px' }} />
          <DisplayText
            value="Won"
            weight="medium"
            uppercase={true}
            color={(config.status & BetFilterStatuses.Won) === BetFilterStatuses.Won ? colors.white : colors.black}
          />
        </Flex>
      </Button>

      <Button
        type={(config.status & BetFilterStatuses.Pending) === BetFilterStatuses.Pending ? 'primary' : 'default'}
        size="large"
        onClick={() => setConfig({ ...config, status: config.status ^ BetFilterStatuses.Pending })}>
        <Flex gap={5}>
          <PieChartOutlined style={{ fontSize: '14px' }} />
          <DisplayText
            value="Pending"
            weight="medium"
            uppercase={true}
            color={
              (config.status & BetFilterStatuses.Pending) === BetFilterStatuses.Pending ? colors.white : colors.black
            }
          />
        </Flex>
      </Button>

      <Button
        type={(config.status & BetFilterStatuses.Lost) === BetFilterStatuses.Lost ? 'primary' : 'default'}
        size="large"
        onClick={() =>
          setConfig({
            ...config,
            status: config.status ^ BetFilterStatuses.Lost,
          })
        }>
        <Flex gap={5}>
          <PieChartOutlined style={{ fontSize: '14px' }} />
          <DisplayText
            value="Lost"
            weight="medium"
            uppercase={true}
            color={(config.status & BetFilterStatuses.Lost) === BetFilterStatuses.Lost ? colors.white : colors.black}
          />
        </Flex>
      </Button>

      <StyledSelect
        mode={'multiple'}
        placeholder="Please select leagues"
        value={config.leagueIds}
        maxTagCount="responsive"
        onChange={(value: string[], _: any) => setConfig({ ...config, leagueIds: value })}
        optionLabelProp="label"
        size="large">
        {leagues.map(l => (
          <Select.Option key={l.id} value={l.id} label={l.name}>
            <Flex align="center" gap="small">
              <Avatar size={30} src={l.countryFlag} />
              <DisplayText value={l.name} />
            </Flex>
          </Select.Option>
        ))}
      </StyledSelect>
    </Flex>
  );
};

const StyledSelect = styled(Select)`
  height: 40px;
  width: 290px;
  font-weight: ${fontWeights['medium']};
  text-transform: uppercase;
`;
