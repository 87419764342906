import { create } from 'zustand';
import { useUsersApi } from '../../api';
import { useMutation } from '@tanstack/react-query';
import { CreateUserRequest } from '../../models';
import { useLoginModalService } from '../LoginModal';
import toast from 'react-hot-toast';

interface State {
  display: boolean;
  setDisplay(display: boolean): void;
}

const useStore = create<State>()(set => ({
  display: false,
  setDisplay: display => set(state => ({ ...state, display })),
}));

export function useSignUpModalService() {
  const store = useStore();
  const { createUser } = useUsersApi();
  const { show: showLogin } = useLoginModalService();

  const createUserResult = useMutation({
    mutationFn: (request: CreateUserRequest) => createUser(request),
    onSuccess: () => {
      toast('User has been created successfully', { position: 'top-center' });
      hide();
      showLogin();
    },
  });

  const signup = (request: CreateUserRequest) => {
    createUserResult.mutate(request);
  };

  const show = () => {
    store.setDisplay(true);
  };

  const hide = () => {
    store.setDisplay(false);
  };

  return {
    display: store.display,
    hide,
    show,
    signup,
  };
}
