import React, { FC, PropsWithChildren } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { styled } from 'styled-components';
import { Flex } from 'antd';
import { colors } from 'src/styles';
import { DisplayText, Space } from '.';

interface Props {
  loading: boolean;
}

export const Loading: FC<PropsWithChildren<Props>> = ({ loading, children }) => {
  if (!loading) {
    return <>{children}</>;
  }

  return (
    <Background justify='center' align='center'>
      <Flex vertical>
        <Spinner />
        <Space size={10} />
        <DisplayText value="Loading..." weight='semiBold' size='sectionTitle' uppercase />
      </Flex>
    </Background>
  );
};

const Background = styled(Flex)`
  width: 100%;
  height: 100vh;
  background-color: ${colors.white};
`;

const Spinner = styled(ClipLoader)`
  width: 150px;
  height: 150px;
  color: ${colors.black};
  margin: 0 auto;
`;