import React, { FC } from 'react';
import { MatchHeader, Bets } from './components';
import { useMatchDetailsService } from './MatchDetailsService';
import { Container, DisplayText, Loading, NavTab, Space } from '../../components';
import { Flex, Menu, MenuProps } from 'antd';
import { Outlet } from 'react-router-dom';
import { styled } from 'styled-components';
import { colors } from '../../styles';

const items: MenuProps['items'] = [
  {
    label: <NavTab to="">MATCH PREVIEW</NavTab>,
    key: '',
  },
  {
    label: <NavTab to="statistics">STATISTICS</NavTab>,
    key: 'statistics',
  },
  {
    label: <DisplayText value="TEAM CHARACTERISTICS" weight="medium" color={colors.white} />,
    key: 'team-characteristics',
    disabled: true,
  },
  {
    label: <NavTab to="player-stats">PLAYER STATS</NavTab>,
    key: 'player-stats',
  },
];

export const MatchDetailsScreen: FC = () => {
  const { matchDetails, loading } = useMatchDetailsService();
  const [selectedKey, setSelectedKey] = React.useState('');

  const handleClickTab: MenuProps['onClick'] = e => {
    setSelectedKey(e.key);
  };

  if (!matchDetails) return <></>;

  return (
    <Loading loading={loading}>
      <MatchHeader matchDetails={matchDetails} />
      <StyledMenu selectedKeys={[selectedKey]} onClick={handleClickTab} mode="horizontal" items={items} />
      <Space size={30} />
      <Container width={1280}>
        <Flex justify="center" gap={20}>
          <Container width={925}>
            <Outlet />
          </Container>

          <Container width={340}>
            <Bets matchDetails={matchDetails} />
          </Container>
        </Flex>
      </Container>
      <Space size={30} />
    </Loading>
  );
};

const StyledMenu = styled(Menu)`
  height: 56px;
  position: sticky;
  top: 0px;
  z-index: 100;
  padding: 5px 0 0 150px;
`;
