import { create } from 'zustand';
import { useMatchesApi, useBetsApi } from '../../api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useCognito } from '../../auth';

interface State {
  display: boolean;
  token?: string;
  matchId?: string;
  show(token: string, matchId: string): void;
  hide(): void;
}

const useStore = create<State>()(set => ({
  display: false,
  token: undefined,
  matchId: undefined,
  show: (token, matchId) => set(state => ({ ...state, display: true, matchId, token })),
  hide: () =>
    set(state => ({
      ...state,
      display: false,
      token: undefined,
      matchId: undefined
    })),
}));

export function useCreateBetModalService() {
  const { display, token, matchId, show, hide } = useStore();
  const { currentUser } = useCognito();
  const queryClient = useQueryClient();
  const { getMatch } = useMatchesApi();
  const { createBet: apiCreateBet } = useBetsApi();

  const id = useMemo(() => currentUser?.getUsername(), [currentUser]);

  const matchDetails = useQuery({
    queryKey: ['match', matchId],
    queryFn: () => (matchId ? getMatch(matchId) : undefined),
    enabled: !!matchId
  });

  const createBet = (matchId: string, token: string, amount: number, description: string) =>
    apiCreateBet(matchId, token, amount, description).then(() => {
      queryClient.invalidateQueries({
        queryKey: ['bets'],
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: ['userStats'],
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: ['user', id],
        refetchType: 'all',
      });
    });

  return {
    display,
    token,
    show,
    matchDetails: matchDetails?.data,
    hide,
    createBet,
  };
}
