import React, { FC } from 'react';
import { Container, DisplayDecimal, DisplayText } from '../../../components';
import { Flex } from 'antd';
import { BetsBarChart } from './BetsBarChart';
import { UserStats } from 'src/models';

interface Props {
  userStats: UserStats;
}

export const BetsStatistics: FC<Props> = ({ userStats }) => {
  const { won, pending, lost, averageOdd, maxOdd } =
    userStats.monthBetStats.length > 0
      ? userStats.monthBetStats[0]!.stats
      : { won: 0, pending: 0, lost: 0, averageOdd: 0, maxOdd: 0 };

  const count = won + pending + lost;
  return (
    <Flex>
      <Container width={410} height={210} bordered innerSpace={20}>
        <DisplayText weight="medium" size="sectionTitle" value={`${count} Bet(s)`} />
        <BetsBarChart />

        <Flex justify="space-between">
          <DisplayText weight="medium" size="normal" value="Average Coefficient" />
          <DisplayDecimal weight="medium" size="normal" value={averageOdd} />
        </Flex>

        <Flex justify="space-between">
          <DisplayText weight="medium" size="normal" value="Maximum Coefficient Played" />
          <DisplayDecimal weight="medium" size="normal" value={maxOdd} />
        </Flex>
      </Container>
    </Flex>
  );
};
