import { Flex } from 'antd';
import React, { FC } from 'react';
import { DisplayHeader } from '../../../components';
import { LeaguePerformance } from './LeaguePerfomance';
import { OddRangePerformance } from './OddRangePerfomance';

export const ProfileStatistics: FC = () => (
  <Flex vertical gap={20}>
    <Flex vertical gap={20}>
      <DisplayHeader value="perfomance by league" />
      <LeaguePerformance />
    </Flex>

    <Flex vertical gap={20}>
      <DisplayHeader value="perfomance by odds" />
      <OddRangePerformance />
    </Flex>
  </Flex>
);
