import React, { FC } from 'react';
import { Flex, Avatar } from 'antd';
import { styled } from 'styled-components';
import { colors } from '../styles';

interface CircledIconProps {
  logo: string;
  size: number;
  color: string;
}

export const CircledIcon: FC<CircledIconProps> = ({ logo, size, color }) => (
  <Flex vertical align="center">
    <CircledBorder $size={size} $color={colors[color]!}>
      <Avatar src={logo} size={size * 0.7} />
    </CircledBorder>
  </Flex>
);

const CircledBorder = styled.div<{ $size: number; $color: string }>`
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid ${props => props.$color};
  border-radius: 50%;
  margin-bottom: 5px;
`;
