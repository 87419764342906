import React, { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { styled } from 'styled-components';
import { colors } from '../styles';

interface Props {
  onClick?: MouseEventHandler<HTMLElement>;
}

export const Clickable: FC<Props & PropsWithChildren> = ({ children, onClick }) => (
  <ClickableContainer onClick={onClick}>{children}</ClickableContainer>
);

export const ClickableContainer = styled.div`
  cursor: pointer !important;

  &:hover span {
    color: ${colors.primary};
  }
`;
