import React from 'react';
import { FC } from 'react';
import { styled } from 'styled-components';
import { fontWeights, FontWeight, fontSizes, FontSize, colors } from '../styles';

interface Props {
  value?: number;
  color?: string;
  size?: FontSize;
  weight?: FontWeight;
  textAlign?: 'start' | 'center' | 'end';
}

export const DisplayPercentage: FC<Props> = ({
  value,
  size = 'small',
  color = colors.black,
  weight = 'normal',
  textAlign = 'start',
}) => {
  if (!value) return <></>;

  const text = `${(value * 100).toFixed(0)}%`;
  return (
    <StyledText $color={color} $size={fontSizes[size]} $weight={fontWeights[weight]} $textAlign={textAlign}>
      {text}
    </StyledText>
  );
};

const StyledText = styled.div<{ $color?: string; $size?: string; $weight?: number; $textAlign?: string }>`
  color: ${props => props.$color};
  font-size: ${props => props.$size};
  font-weight: ${props => props.$weight};
  text-align: ${props => props.$textAlign};
`;
