import React, { FC } from 'react';
import { AggregatedResearchPrediction } from '../../../models';
import { Flex } from 'antd';
import { Container, DisplayText, Space } from '../../../components';
import { CartesianGrid, XAxis, YAxis, Line, LineChart, Tooltip, Legend } from 'recharts';
import { groupBy } from '../../../utils/groupBy';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

export type GroupKey = 'successRate' | 'frequency';

export interface EvaluatorColor {
  evaluator: string;
  color: string;
}

export interface Props {
  title: string;
  groupKey: GroupKey;
  evaluatorColors: EvaluatorColor[];
  points: AggregatedResearchPrediction[];
}

export const AggregationChart: FC<Props> = ({ title, groupKey, evaluatorColors, points }) => {
  const rankGroups = groupBy(points, 'valueRank');
  const evaluators = Object.keys(groupBy(points, 'evaluator'));

  evaluatorColors = evaluatorColors.filter(ec => evaluators.includes(ec.evaluator));

  const [hiddenLines, setHiddenLines] = React.useState<string[]>([]);

  const toggleLine = React.useCallback(
    (prediction: Payload) => {
      if (hiddenLines.includes(prediction.value)) {
        setHiddenLines(hiddenLines.filter(x => x !== prediction.value));
      } else {
        setHiddenLines([...hiddenLines, prediction.value]);
      }
    },
    [hiddenLines],
  );

  const data = [];
  for (var value in rankGroups) {
    const dataItem: any = {};
    dataItem.value = value;
    const rankItems = rankGroups[value];
    for (let { evaluator } of evaluatorColors) {
      var evaluatorItem = rankItems!.find(x => x.evaluator === evaluator);
      if (evaluatorItem) {
        dataItem[evaluator] = evaluatorItem[groupKey];
      } else {
        dataItem[evaluator] = 0;
      }
    }

    data.push(dataItem);
  }

  return (
    <Flex vertical>
      <Space size={20} />
      <Container bordered innerSpace={20}>
        <DisplayText value={title} textAlign="center" />
        <Space size={10} />
        <LineChart width={550} height={250} data={data}>
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          <XAxis dataKey="value" />
          <YAxis />
          <Tooltip />
          <Legend layout="vertical" onClick={e => toggleLine(e)} />
          {evaluatorColors.map(e => (
            <Line
              type="monotone"
              dataKey={e.evaluator}
              fillOpacity={0.5}
              stroke={e.color}
              hide={hiddenLines.includes(e.evaluator)}
            />
          ))}
        </LineChart>
      </Container>
    </Flex>
  );
};
