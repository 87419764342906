import React from 'react';
import { useApi } from './api';
import { CreateUserRequest, User, UserStats } from '../models';
import { GetUsersResponse } from '../models/User/GetUsersResponse';

export function useUsersApi() {
  const { api } = useApi();

  const getUser = React.useCallback((id: string) => api<null, User>('GET', `users/${id}`), [api]);

  const getUsers = React.useCallback(() => api<null, GetUsersResponse>('GET', 'users'), [api]);

  const getProfile = React.useCallback(
    async (id: string) => {
      return await api<null, UserStats>('GET', `users/${id}/profile`, null);
    },
    [api],
  );

  const createUser = React.useCallback(
    (req: CreateUserRequest) => api<CreateUserRequest, null>('POST', 'users', null, req),
    [api],
  );

  return { createUser, getUser, getUsers, getProfile };
}
