import { UserOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import { colors } from 'src/styles';
import styled from 'styled-components';


interface Props {
  size: number;
}

export const UserAvatar: FC<Props> = ({ size }) => (
  <Container $size={size} $borderSize={Math.max(size / 30, 2)}>
    <Avatar $size={size / 1.7 } />
  </Container>
);

const Container = styled.div<{ $size: number; $borderSize: number }>`
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  background-color: #f0f0f0;
  border-radius: 75px;
  border: ${props => props.$borderSize}px solid #00be8c;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Avatar = styled(UserOutlined)<{ $size: number }>`
  font-size: ${props => props.$size}px;
  color: ${colors.primaryDark};
`;
